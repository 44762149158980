import {Iso8601} from '@hconnect/uikit'
import {Box, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'
import {getRunningTimeDuration} from '../runningTimes'

type StoppageSplitDurationDetailsProps = {
  stoppageTimeRangeUtc: [Iso8601, Iso8601 | undefined]
  splitDateUtc: Iso8601
  timezone: string
  translationPrefix?: string
}

export const StoppageSplitDurationDetails = ({
  stoppageTimeRangeUtc,
  splitDateUtc,
  timezone,
  translationPrefix
}: StoppageSplitDurationDetailsProps) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const [stoppageStart, stoppageEnd] = stoppageTimeRangeUtc

  return (
    <Box display="flex" gap={1}>
      <Stack textAlign="left" data-test-id="split-details-start">
        <Typography variant="caption">
          {t(getTranslationKey('shiftEvent.splitStoppage.part1', translationPrefix))}
        </Typography>
        <Typography variant="subtitle1">
          {getRunningTimeDuration({
            beginUtc: stoppageStart,
            endUtc: splitDateUtc,
            translationFn: (key) => t(getTranslationKey(key, translationPrefix)),
            timezone,
            language
          })}
        </Typography>
      </Stack>
      <Stack textAlign="end" data-test-id="split-details-end">
        <Typography variant="caption">
          {t(getTranslationKey('shiftEvent.splitStoppage.part2', translationPrefix))}
        </Typography>
        <Typography variant="subtitle1">
          {getRunningTimeDuration({
            beginUtc: splitDateUtc,
            endUtc: stoppageEnd,
            translationFn: (key) => t(getTranslationKey(key, translationPrefix)),
            timezone,
            language
          })}
        </Typography>
      </Stack>
    </Box>
  )
}
