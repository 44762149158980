import {PermissionType} from '@hconnect/apiclient'
import {useNotification} from '@hconnect/uikit'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import React, {Dispatch, SetStateAction, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  FILE_MAX_SIZE_IN_BYTES,
  FILE_REJECTED_TRANSLATIONS,
  MAX_ALLOWED_ATTACHMENTS
} from '../../consts'
import {AttachmentFile} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {
  AllowedFileFormat,
  AttachementUploadConfig,
  AttachmentUpload,
  FileUploadEvent,
  FileUploadEventType
} from './AttachmentUpload'

import {headlineSx} from '@hconnect/common/styles'

const ALLOWED_IMAGE_FORMATS: AllowedFileFormat[] = ['.png', '.jpg', '.jpeg']
const ALLOWED_WORKSHEET_FORMATS: AllowedFileFormat[] = ['.xlsx', '.xls']
const ALLOWED_VIDEO_FORMATS: AllowedFileFormat[] = ['.mp4', '.3gp', '.mov']

export const EventAttachmentUpload: React.FC<{
  attachments: Array<AttachmentFile>
  setAttachments: Dispatch<SetStateAction<AttachmentFile[]>>
  isMovieAttachmentEnabled?: boolean
  hasPermission: (permission: PermissionType) => boolean
  translationPrefix?: string
}> = ({
  attachments,
  setAttachments,
  isMovieAttachmentEnabled,
  hasPermission,
  translationPrefix
}) => {
  const {t} = useTranslation()
  const {notify} = useNotification()

  const supportedFormats: AllowedFileFormat[] = useMemo(
    () => [
      '.pdf',
      ...ALLOWED_IMAGE_FORMATS,
      ...ALLOWED_WORKSHEET_FORMATS,
      ...(isMovieAttachmentEnabled ? ALLOWED_VIDEO_FORMATS : [])
    ],
    [isMovieAttachmentEnabled]
  )

  const attachmentUploadConfig = useMemo(
    (): AttachementUploadConfig => ({
      allowedFormats: supportedFormats,
      maxAttachements: MAX_ALLOWED_ATTACHMENTS,
      maxFileSizeMB: FILE_MAX_SIZE_IN_BYTES / (1024 * 1024),
      isAddingEnabled: hasPermission('ADD_DOCUMENTS'),
      isDeletingEnabled: hasPermission('DELETE_DOCUMENTS')
    }),
    [supportedFormats, hasPermission]
  )

  const handleUploadEvent = useCallback(
    (event: FileUploadEvent) => {
      switch (event.type) {
        case FileUploadEventType.FILE_DUPLICATED:
          return notify(
            'info',
            `${t(getTranslationKey('dropzone.duplicateFiles', translationPrefix))}${event.fileName}`
          )
        case FileUploadEventType.FILE_SIZE_TOO_LARGE:
          return notify(
            'info',
            `${t(getTranslationKey(FILE_REJECTED_TRANSLATIONS[0], translationPrefix))} ${
              event.fileName
            }`
          )
        case FileUploadEventType.FILE_TYPE_INVALID:
          return notify(
            'info',
            `${t(getTranslationKey(FILE_REJECTED_TRANSLATIONS[1], translationPrefix))} ${
              event.fileName
            }`
          )
        case FileUploadEventType.FILE_LIMIT_REACHED:
          return notify(
            'info',
            `${t(getTranslationKey('dropzone.fileLimitReached', translationPrefix))}${
              event.fileName
            }`
          )
        case FileUploadEventType.FILE_REMOVED:
          return notify(
            'warning',
            <>
              {t(getTranslationKey('attachmentDeleted', translationPrefix))} &nbsp; {t('undo')}
            </>,
            {onClickClose: event.undoRemove}
          )
      }
    },
    [notify, t, translationPrefix]
  )

  return (
    <AttachmentUpload
      attachments={attachments}
      setAttachments={setAttachments}
      uploadEventsCallback={handleUploadEvent}
      config={attachmentUploadConfig}
      attachmentsTitle={
        <CardSectionTitle>
          {`${t(
            getTranslationKey('shiftEvent.attachments', translationPrefix)
          )} ${`${attachments?.length}/${MAX_ALLOWED_ATTACHMENTS}`}`}
        </CardSectionTitle>
      }
      containerSx={headlineSx()}
    />
  )
}
