import {
  AssignmentOutlined,
  FlashOn,
  InfoOutlined,
  LocalHospital,
  Spa,
  Tune,
  Warning,
  WorkspacePremium,
  InsertInvitationOutlined,
  WbIncandescentOutlined
} from '@mui/icons-material'
import {SxProps, Theme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EventType} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {IconWithLabel} from './IconWithLabel'

export const eventTypeIcons: Record<EventType | 'stoppage' | 'unplannedStoppage', React.ReactNode> =
  {
    malfunction: <Warning />,
    task: <AssignmentOutlined />,
    information: <InfoOutlined />,
    idea: <WbIncandescentOutlined sx={{transform: 'rotate(180deg)'}} />,
    healthAndSafety: <LocalHospital />,
    environment: <Spa />,
    parameterChange: <Tune />,
    incident: <FlashOn />,
    circumstance: <FlashOn />,
    plannedStoppage: <InsertInvitationOutlined />,
    stoppage: <FlashOn />,
    unplannedStoppage: <FlashOn />,
    quality: <WorkspacePremium />
  }

export const EventTypeLabel: React.FC<{
  eventType: string
  iconOnly?: boolean
  eventTypeLabelPrefix?: string
  sx?: SxProps<Theme>
  'data-test-id'?: string
  translationPrefix?: string
}> = (props) => {
  const {t} = useTranslation()

  return (
    <IconWithLabel
      sx={props.sx}
      icon={eventTypeIcons[props.eventType]}
      iconOnly={props.iconOnly}
      label={`${props.eventTypeLabelPrefix ? `${props.eventTypeLabelPrefix} ` : ''}${t(
        getTranslationKey(`shiftEvent.eventType.${props.eventType}`, props.translationPrefix)
      )}`}
      data-test-id={props['data-test-id']}
    />
  )
}
