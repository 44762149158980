import {Mic, MicNone} from '@mui/icons-material'
import {
  Box,
  ClickAwayListener,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import SimpleMDE from 'easymde'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import SimpleMdeReact from 'react-simplemde-editor'
import SpeechRecognition, {useSpeechRecognition} from 'react-speech-recognition'
import 'easymde/dist/easymde.min.css'

import {MAX_DESCRIPTION_CHARACTER_LIMIT} from '../../consts'
import {useLongPress} from '../../hooks/useLongPress'
import {removeMarkdownTags} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'

import {FormIconButton} from './FormIconButton'

type Props = {
  'data-test-id'?: string
  current?: string
  errorCode?: string
  onChange: (next: string) => void
  required?: boolean
  disabled?: boolean
  translationPrefix?: string
  enableSpellChecking?: boolean
}

export const EventDescriptionBox: React.FC<Props> = ({
  'data-test-id': dataTestId,
  current,
  errorCode,
  onChange,
  required,
  disabled,
  translationPrefix,
  enableSpellChecking = false
}) => {
  const {t, i18n} = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    finalTranscript,
    resetTranscript
  } = useSpeechRecognition()
  const [text, setText] = useState<string>(current || '')
  const [speechText, setSpeechText] = useState<string>('')
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false)
  const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(true)

  useEffect(() => {
    if (transcript) {
      setSpeechText(transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (finalTranscript) {
      setSpeechText('')
      const value: string = text ? `${text} ${finalTranscript}` : finalTranscript
      setText(value)
      resetTranscript()
      onChange(value)
    }
  }, [onChange, text, finalTranscript, resetTranscript])

  const startRecording = useLongPress(
    () => {
      setIsTooltipOpen(false)
      void SpeechRecognition.startListening({continuous: true, language: i18n.language})
      setShouldShowTooltip(false)
    },
    () => void SpeechRecognition.stopListening(),
    100
  )

  const handleChange = useCallback(
    (value: string) => {
      if (!listening) {
        setText(value)
        onChange(value)
      }
    },
    [listening, onChange]
  )

  const transcriptedText = speechText && text ? ` ${speechText}` : speechText

  const options = useMemo<SimpleMDE.Options>(() => {
    return {
      toolbar: !disabled && [
        'bold',
        'italic',
        'strikethrough',
        '|',
        'quote',
        'unordered-list',
        'ordered-list',
        '|',
        'preview'
      ],
      spellChecker: enableSpellChecking,
      status: false,
      minHeight: '25px',
      inputStyle: 'contenteditable',
      placeholder: `${t(getTranslationKey('shiftEvent.label.description', translationPrefix))}${
        required ? ' *' : ''
      }`
    }
  }, [enableSpellChecking, disabled, required, t, translationPrefix])

  return (
    <Box display="flex" gap={2}>
      <Stack
        alignItems="flex-end"
        width="100%"
        data-test-id={dataTestId ?? 'event-form-description'}
      >
        <Box
          component={SimpleMdeReact}
          value={text + transcriptedText}
          onChange={handleChange}
          options={options}
          width="100%"
          sx={{
            backgroundColor: theme.palette.grey['100'],
            '.CodeMirror': {
              maxHeight: '130px',
              backgroundColor: theme.palette.grey['100']
            },
            '.CodeMirror-scroll': {
              maxHeight: '120px'
            },
            '.CodeMirror .cm-spell-error:not(.cm-url):not(.cm-comment):not(.cm-tag):not(.cm-word)':
              {
                background: 'unset'
              }
          }}
        />
        {errorCode && (
          <Typography variant="caption" sx={{color: theme.palette.error.light}}>
            {t(getTranslationKey(errorCode, translationPrefix))}
          </Typography>
        )}
        <Typography pt={0.5} pr={0.5} variant="caption">
          {removeMarkdownTags(text).length}/{MAX_DESCRIPTION_CHARACTER_LIMIT}
        </Typography>
      </Stack>
      {isMobile && browserSupportsSpeechRecognition && (
        <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            arrow
            disableHoverListener
            disableFocusListener
            open={isTooltipOpen}
            title={t(getTranslationKey('shiftEvent.label.pressAndHoldRecord', translationPrefix))}
            placement="top"
            onClose={() => setIsTooltipOpen(false)}
          >
            <FormIconButton
              {...startRecording}
              onClick={() => {
                if (shouldShowTooltip) {
                  setIsTooltipOpen(!isTooltipOpen)
                } else {
                  setShouldShowTooltip(true)
                }
              }}
              data-test-id="event-description-box-microphone-button"
            >
              {!listening ? <Mic /> : <MicNone />}
            </FormIconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
    </Box>
  )
}
