import {Grid, SxProps, Typography} from '@mui/material'
import React from 'react'

import {REGULAR_SIZE} from '../../consts'
import {EventType} from '../../types'
import {EventTypeLabel} from '../shiftEventLabels'

type TagProps = {
  eventType: EventType
  eventTypeLabelPrefix?: string
  sx?: SxProps
  'data-test-id'?: string
  translationPrefix?: string
}

export const EventTypeTag: React.FC<TagProps> = ({
  eventType,
  'data-test-id': dataTestId,
  eventTypeLabelPrefix,
  sx,
  translationPrefix
}) => {
  return (
    <Grid item {...REGULAR_SIZE} sx={{pl: {xs: 0}, width: '100%', whiteSpace: 'pre-line', ...sx}}>
      <Typography variant="h4" color="textPrimary">
        <EventTypeLabel
          eventType={eventType}
          eventTypeLabelPrefix={eventTypeLabelPrefix}
          data-test-id={dataTestId}
          translationPrefix={translationPrefix}
        />
      </Typography>
    </Grid>
  )
}
