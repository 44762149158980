import {MenuItem, TextField} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX} from '../../consts'
import {categories, Category, TaskRepetitionUnit, taskRepetitionUnits} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

export type CommonProps<T extends string | number> = {
  'data-test-id': string
  disabled?: boolean
  errorText?: React.ReactNode
  fullWidth?: boolean
  onChange: (newValue: T, event: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  value?: T
  hiddenOptions?: ReadonlyArray<T>
  translationPrefix?: string
}

type Props<T extends string | number> = CommonProps<T> & {
  keyName: string
  options: ReadonlyArray<T>
  customTemplate?: (key: T) => React.ReactNode
}

export function CommonDropDown<T extends string | number>({
  keyName,
  options,
  customTemplate,
  hiddenOptions = [],
  disabled,
  errorText,
  fullWidth,
  onChange,
  required,
  value,
  'data-test-id': dataTestId,
  translationPrefix
}: Props<T>) {
  const {t} = useTranslation()

  return (
    <TextField
      data-test-id={dataTestId}
      disabled={disabled}
      error={!!errorText}
      fullWidth={fullWidth ?? true}
      helperText={errorText}
      label={
        t(getTranslationKey(`shiftEvent.label.${keyName}`, translationPrefix)) +
        (!required
          ? ` (${t(getTranslationKey('shiftEvent.label.optional', translationPrefix))})`
          : '')
      }
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as T, event)
      }}
      required={required}
      select
      value={value ?? ''}
      variant="filled"
      inputProps={{'data-test-id': `${dataTestId}-input`}}
      InputProps={INPUT_FORM_FIELD_SX}
    >
      {!required && (
        <MenuItem key={'empty'} value={0} data-test-id={`${dataTestId}-empty`}>
          {t(getTranslationKey('shiftEvent.action.noneOfThat', translationPrefix))}
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem
          key={option}
          value={option}
          sx={{display: hiddenOptions.includes(option) ? 'none' : 'block'}}
          data-test-id={`${dataTestId}-${option}`}
        >
          {customTemplate
            ? customTemplate(option)
            : t(getTranslationKey(`shiftEvent.${keyName}.${option}`, translationPrefix))}
        </MenuItem>
      ))}
    </TextField>
  )
}

export const CategoryDropDown: React.FC<CommonProps<Category>> = (props) => {
  return <CommonDropDown {...props} keyName="category" options={categories} />
}

export const TaskRepetitionDropDown: React.FC<CommonProps<TaskRepetitionUnit>> = (props) => {
  return <CommonDropDown {...props} keyName="recurring" options={taskRepetitionUnits} />
}

export const CustomTaskRepetitionDropDown: React.FC<CommonProps<'daily' | 'weekly'>> = (props) => {
  return <CommonDropDown {...props} keyName="recurring" options={['daily', 'weekly']} />
}
