import axios, {AxiosError} from 'axios'
import queryString from 'query-string'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useApi} from '../../hooks/useApi'
import {
  DEFAULT_ITEMS_PER_PAGE,
  MY_IN_APP_NOTIFICATIONS_SEGMENT,
  NOTIFICATIONS_SEGMENT
} from '../consts'
import {
  Notification,
  NotificationsPayload,
  NotificationResponse,
  NotificationsOptions,
  NotificationsResult
} from '../types'
import {mapNotifications} from '../utils/notifications.utils'

export const useNotifications = (
  params: NotificationsOptions,
  onSuccess: (notifications: Notification[]) => void
): NotificationsResult => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()
  const [data, setData] = useState<Notification[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<AxiosError | null>(null)

  const [offset, setOffset] = useState<number>(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const offsetRef = useRef<number>()
  offsetRef.current = offset

  const handleFetchNotifications = useCallback(
    async (fetchNextPage?: boolean) => {
      const payload: NotificationsPayload = {
        ...params,
        language: language.split('-')[0].toUpperCase(),
        limit: DEFAULT_ITEMS_PER_PAGE,
        offset: offsetRef.current || 0
      }
      try {
        const response = await publicApi.get<NotificationResponse[]>(
          `/${NOTIFICATIONS_SEGMENT}/${MY_IN_APP_NOTIFICATIONS_SEGMENT}?${queryString.stringify(
            payload
          )}`
        )
        const mappedNotifications = mapNotifications(response.data)
        setData((current) =>
          fetchNextPage ? [...current, ...mappedNotifications] : mappedNotifications
        )
        setError(null)
        onSuccess(mappedNotifications)
        if (response.data.length) {
          setOffset((current) => current + DEFAULT_ITEMS_PER_PAGE)
        }
        setHasNextPage(response.data.length === DEFAULT_ITEMS_PER_PAGE)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error)
        } else {
          console.error(error)
        }
      }
      setIsLoading(false)
    },
    [params, language, publicApi, onSuccess]
  )

  const handleUpdateNotification = useCallback(
    async (id: string) => {
      const notificationIndex = data.findIndex((notification) => notification.id === id)

      if (notificationIndex !== -1) {
        try {
          const newItem = await publicApi.get<NotificationResponse[]>(
            `/${NOTIFICATIONS_SEGMENT}/${MY_IN_APP_NOTIFICATIONS_SEGMENT}?${queryString.stringify({
              ...params,
              limit: 1,
              offset: notificationIndex,
              language: language.split('-')[0].toUpperCase()
            })}`
          )
          const mappedNotification = mapNotifications(newItem.data)[0]
          const updated = data.map((notification) =>
            notification.id === id ? mappedNotification : notification
          )
          setData(updated)
        } catch (e) {
          if (axios.isAxiosError(e)) {
            setError(e)
          } else {
            console.error(e)
          }
        }
      }
    },
    [data, language, params, publicApi]
  )

  const refetch = useCallback(
    async (omitLoadingState?: boolean, notificationIdToRefresh?: string) => {
      if (notificationIdToRefresh) {
        await handleUpdateNotification(notificationIdToRefresh)
        return
      }

      if (!omitLoadingState) {
        setIsLoading(true)
      }
      setOffset(0)
      return handleFetchNotifications()
    },
    [handleFetchNotifications, handleUpdateNotification]
  )

  const fetchNextPage = useCallback(async () => {
    await handleFetchNotifications(true)
  }, [handleFetchNotifications])

  useEffect(() => {
    void handleFetchNotifications()
  }, [handleFetchNotifications])

  return {data, isLoading, error, refetch, fetchNextPage, hasNextPage}
}
