import {debounce} from 'lodash'
import {useCallback} from 'react'

const TRACK_SCROLL_DELAY_IN_MS = 100

const isBottom = (el) => {
  return el.getBoundingClientRect().bottom <= window.innerHeight
}

type ScrollableContext = {
  trackScrolling: () => void
}

export const useEndOfPageScrollDebounce = (
  callback: () => void,
  active: boolean
): ScrollableContext => {
  const trackScrollingHandler = useCallback(() => {
    const wrappedElement = document.querySelector('body')
    if (isBottom(wrappedElement) && active) {
      callback()
    }
  }, [active, callback])

  const trackScrolling = debounce(trackScrollingHandler, TRACK_SCROLL_DELAY_IN_MS)

  return {trackScrolling}
}
