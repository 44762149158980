import {isObject} from 'lodash'

const DEFAULT_RETRIES = 3

export function retry(failureCount: number, error: unknown) {
  if (failureCount >= DEFAULT_RETRIES) {
    return false
  }
  if (isObject(error)) {
    const status = error['response']?.['status']
    return error['message'] === 'Network Error' || status === 500
  }
  return true
}

export function retryMutation(failureCount: number, error: unknown) {
  if (failureCount >= DEFAULT_RETRIES) {
    return false
  }
  if (isObject(error)) {
    const status = error['response']?.['status']
    return error['message'] === 'Network Error' || status === 500 || status === 409
  }
  return true
}
