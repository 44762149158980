import {User} from '@hconnect/apiclient/src/response/User'
import {Error404Page, LegalDocumentRenderer} from '@hconnect/uikit/src/lib2'
import {CircularProgress, Paper} from '@mui/material'
import React, {ElementType} from 'react'
import {useTranslation} from 'react-i18next'

import {useLegalDocument, LegalDocumentsType} from '../../hooks/legal/useLegalDocuments'

interface LegalPageType {
  user: User
  type: LegalDocumentsType
  routerLinkComponent: ElementType
}

export const LegalPage: React.FC<LegalPageType> = ({type, user, routerLinkComponent}) => {
  const {t} = useTranslation()

  const country = user?.country?.toLowerCase()
  const defaultLocale = user?.defaultLocale?.toLowerCase()?.split('-')?.[0]

  const {
    queryInfo: {error, isFetching, data: content}
  } = useLegalDocument(type, country, defaultLocale)

  if (isFetching) {
    return <CircularProgress data-test-id="legal-page-loader" />
  } else if (!content || error) {
    return (
      <Error404Page
        caption={t('legal.notFound')}
        description={t('legal.notFoundDescription')}
        goToCaption={t('errorPage.goToHome')}
        goToLink={'/'}
        RouterLink={routerLinkComponent}
      />
    )
  }

  return (
    <Paper sx={{margin: 3, padding: 3}}>
      <LegalDocumentRenderer body={content} />
    </Paper>
  )
}
