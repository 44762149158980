import axios, {AxiosError} from 'axios'
import {useCallback, useState} from 'react'

import {useApi} from '../../hooks/useApi'
import {MY_IN_APP_NOTIFICATIONS_SEGMENT, NOTIFICATIONS_SEGMENT} from '../consts'

export type UseDeleteNotificationParams = {
  isLoading: boolean
  error: AxiosError | null
  removeNotification: (id: string) => Promise<void>
  clearState: () => void
}

export const useDeleteNotification = (
  onSuccess?: (id: string) => void
): UseDeleteNotificationParams => {
  const {publicApi} = useApi()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<AxiosError | null>(null)

  const clearState = useCallback(() => {
    setError(null)
  }, [])

  const handleRemove = useCallback(
    async (id: string) => {
      setIsLoading(true)
      try {
        await publicApi.delete<string[]>(
          `/${NOTIFICATIONS_SEGMENT}/${MY_IN_APP_NOTIFICATIONS_SEGMENT}`,
          {data: [id]}
        )
        onSuccess?.(id)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(error)
        } else {
          console.error(error)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [onSuccess, publicApi]
  )

  return {removeNotification: handleRemove, isLoading, error, clearState}
}
