import {SortDirection} from '@mui/material'
import {useState, useMemo} from 'react'

import {toggleSortDir} from '../utils/sorting'

interface SortingOptions<T, K> {
  initialOrderByKey?: K
  initialSortDir: SortDirection
  data: T[]
  sort: (list: T[], orderByKey: K, sortDir: SortDirection) => T[]
}

export const useSorting = <T extends object, K extends string>({
  initialOrderByKey,
  initialSortDir,
  data,
  sort
}: SortingOptions<T, K>) => {
  const [orderByKey, setOrderByKey] = useState<K | undefined>(initialOrderByKey)
  const [sortDir, setSortDir] = useState<SortDirection>(initialSortDir)

  return useMemo(
    () => ({
      orderByKey,
      sortDir,
      onSortClick: (columnKey: K) => {
        setSortDir((dir) => toggleSortDir(dir, columnKey, orderByKey))
        setOrderByKey(columnKey)
      },
      sortedList: orderByKey && sortDir ? sort(data, orderByKey, sortDir) : data
    }),
    [data, orderByKey, sort, sortDir]
  )
}
