import {Button, Box, ButtonProps} from '@mui/material'
import React from 'react'

export const FilterActionButton: React.FC<ButtonProps> = ({children, sx, ...buttonProps}) => (
  <Box display="flex" justifyContent="center">
    <Button
      sx={{
        border: '1px solid rgba(31, 73, 94, 0.1)',
        ...sx
      }}
      variant="text"
      {...buttonProps}
    >
      {children}
    </Button>
  </Box>
)
