import {Iso8601} from '@hconnect/uikit'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import {Button, DialogActions, DialogContent, Typography, Alert, Stack} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {isNumber} from 'lodash'
import moment from 'moment-timezone'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {StoppageDto as Stoppage, StoppageKind} from '../../types'
import {getStoppageSplitInitialData} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'

import {SliderThumbBox, StoppageSlider} from './StoppageSlider'
import {StoppageSplitDateTimeEditor} from './StoppageSplitDateTimeEditor'
import {StoppageSplitDurationDetails} from './StoppageSplitDurationDetails'

type SplitStoppageDialogProps = {
  timezone: string
  stoppage: Stoppage
  open: boolean
  onClose: () => void
  onSplitSubmit: (initialStoppageData: Partial<Stoppage>) => void
  translationPrefix?: string
  pxTrendAvailable?: boolean
}

export const SplitStoppageDialog: React.FC<SplitStoppageDialogProps> = ({
  timezone,
  stoppage,
  open,
  onClose,
  onSplitSubmit,
  translationPrefix,
  pxTrendAvailable = false
}) => {
  const {t} = useTranslation()
  const isPlannedStoppage = stoppage.eventType === StoppageKind.PlannedStoppage
  const stoppageStart = moment.utc(stoppage.stoppageStart).tz(timezone)
  const stoppageEnd = moment.utc(stoppage.stoppageEnd).tz(timezone)
  const duration = moment.duration(stoppageEnd.diff(stoppageStart))
  const totalMinutes = duration.asMinutes()

  const defaultSplitValueInMins = Math.ceil(totalMinutes / 2)
  const [splitValueInMins, _setSplitValueInMins] = useState<number>(defaultSplitValueInMins)
  const [splitDateUtc, _setSplitDateUtc] = useState<Iso8601>(
    stoppageStart.clone().add(defaultSplitValueInMins, 'minutes').toISOString()
  )

  const setSplitValueInMins = useCallback(
    (value: number) => {
      _setSplitValueInMins(value)
      const splitTime = stoppageStart.clone().add(value, 'minutes')
      _setSplitDateUtc(splitTime.toISOString())
    },
    [stoppageStart]
  )

  const setSplitDateUtc = useCallback(
    (date: Iso8601) => {
      const splitTime = moment.utc(date).tz(timezone)
      const value = splitTime.diff(stoppageStart, 'minutes')
      _setSplitValueInMins(value)
      _setSplitDateUtc(date)
    },
    [stoppageStart, timezone]
  )

  const stoppageTimeRangeUtc: [Iso8601, Iso8601 | undefined] = [
    stoppage.stoppageStart,
    stoppage.stoppageEnd
  ]

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{sx: {width: '514px'}}}>
      <DialogTitle>
        <Typography
          onClick={onClose}
          sx={{cursor: 'pointer'}}
          variant="subtitle2"
          color="primary"
          display="flex"
          alignItems="center"
          component="span"
          width="fit-content"
        >
          <ChevronLeftIcon />
          {t(getTranslationKey('shiftEvent.splitStoppage.backToOriginal', translationPrefix), {
            originalStoppage: `${stoppage.equipment.text} ${t(
              getTranslationKey(`shiftEvent.stoppage.${stoppage.eventType}`, translationPrefix)
            )}`
          })}
        </Typography>
        <Typography variant="h3" component="span">
          {t(getTranslationKey('shiftEvent.splitStoppage.title', translationPrefix))}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4} alignItems={'center'}>
          <StoppageSplitDateTimeEditor
            stoppageTimeRangeUtc={stoppageTimeRangeUtc}
            timezone={timezone}
            splitDateUtc={splitDateUtc}
            setSplitDateUtc={setSplitDateUtc}
            translationPrefix={translationPrefix}
          />
          {pxTrendAvailable && (
            <StoppageSlider
              value={splitValueInMins}
              onChange={(_, value: number | number[]) => {
                isNumber(value) && setSplitValueInMins(value)
              }}
              min={0}
              max={totalMinutes}
              // valueLabelDisplay="auto" // Add back with TODO in ./StoppageSlider.tsx
              slotProps={
                {
                  // valueLabel: { // Add back with  TODO in ./StoppageSlider.tsx
                  //   stoppage
                  // }
                }
              }
              slots={{thumb: SliderThumbBox}} // valueLabel: SliderValueLabel, Add back with  TODO in ./StoppageSlider.tsx
              isPlannedStoppage={isPlannedStoppage}
              data-test-id="stoppage-split-slider"
            />
          )}
          <StoppageSplitDurationDetails
            stoppageTimeRangeUtc={stoppageTimeRangeUtc}
            splitDateUtc={splitDateUtc}
            timezone={timezone}
            translationPrefix={translationPrefix}
          />
          <Alert color="info" icon={false}>
            {t(getTranslationKey('shiftEvent.splitStoppage.footNote', translationPrefix))}
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" startIcon={<CloseIcon />}>
          {t(getTranslationKey('shiftEvent.action.cancel', translationPrefix))}
        </Button>
        <Button
          onClick={() => {
            const initialStoppageData: Partial<Stoppage> = getStoppageSplitInitialData(
              stoppage,
              splitDateUtc
            )
            onSplitSubmit(initialStoppageData)
            onClose()
          }}
          color="primary"
          variant="contained"
          disabled={splitValueInMins <= 0 || splitValueInMins >= totalMinutes}
          startIcon={<ContentCutIcon />}
          data-test-id="split-stoppage-button"
        >
          {t(getTranslationKey('shiftEvent.action.split', translationPrefix))}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
