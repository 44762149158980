import moment from 'moment-timezone'

const padNum = (num: number, padCount = 2) => num.toString().padStart(padCount, '0')

export const dateInTimeZone = (
  timeZone: string,
  years: number,
  months: number,
  days: number,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliSecs = 0
): Date => {
  const dateStr = `${padNum(years, 4)}-${padNum(months)}-${padNum(days)}T${padNum(hours)}:${padNum(
    minutes
  )}:${padNum(seconds)}.${padNum(milliSecs, 3)}`
  return moment.tz(dateStr, timeZone).toDate()
}

export const localTimeToTimeZoneTime = (localTime: Date, timeZone: string): Date =>
  dateInTimeZone(
    timeZone,
    localTime.getFullYear(),
    localTime.getMonth() + 1,
    localTime.getDate(),
    localTime.getHours(),
    localTime.getMinutes(),
    localTime.getSeconds(),
    localTime.getMilliseconds()
  )

export const timeZoneTimeToLocalTime = (
  timeZoneDate: Date | string | number,
  timeZone: string
): Date => {
  const date = moment.utc(timeZoneDate).tz(timeZone)
  return new Date(
    date.year(),
    date.month(),
    date.date(),
    date.hour(),
    date.minute(),
    date.second(),
    date.millisecond()
  )
}
