import {Popper} from '@material-ui/core'
import {Box, Paper, Slider, SliderThumb, SliderValueLabelProps, styled} from '@mui/material'
import {SliderProps} from '@mui/material/Slider/Slider'
import {useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {Stoppage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {RUNNING_TIME_COLOR} from '../runningTimes'

const SLIDER_HEIGHT = '48px'
const SLIDER_BORDER_RADIUS = '6px'

/**
 * Fixed total number of steps in the slider.
 * Every step will have varied time jump depending on the duration of the
 * stoppage on total stoppage duration
 */
const MAX_SLIDER_STEPS = 200

// Note: unused function
// This logic can be used if we want to have a fixed number of steps in the slider
export const getSliderStep = (totalMinutes: number) => {
  if (totalMinutes <= MAX_SLIDER_STEPS) return totalMinutes
  const step = Math.ceil(totalMinutes / MAX_SLIDER_STEPS)
  return step
}

type StoppageSliderProps = SliderProps & {
  isPlannedStoppage: boolean
  'data-test-id'?: string
}

export const StoppageSlider = ({isPlannedStoppage, ...rest}: StoppageSliderProps) => {
  return (
    <Slider
      {...rest}
      sx={{
        '& .MuiSlider-track, & .MuiSlider-rail': {
          border: 'none',
          borderRadius: SLIDER_BORDER_RADIUS,
          height: SLIDER_HEIGHT,
          backgroundColor: isPlannedStoppage
            ? RUNNING_TIME_COLOR.planned
            : RUNNING_TIME_COLOR.stopped
        },
        '& .MuiSlider-rail': {
          opacity: 1
        },
        '& .MuiSlider-thumb': {
          height: SLIDER_HEIGHT,
          backgroundColor: 'transparent',
          borderRadius: '0',
          '&:hover, & .Mui-active, & .Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${
              isPlannedStoppage ? 'rgba(1, 98, 196, 0.16)' : 'rgba(255, 0, 255, 0.5)'
            }`,
            cursor: 'row-resize'
          }
        },
        '& .MuiSlider-valueLabel': {}
      }}
    />
  )
}

const LeftArrow = styled('span')(({theme}) => ({
  border: '4px solid transparent',
  borderRightColor: theme.palette.common.black
}))

const RightArrow = styled('span')(({theme}) => ({
  border: '4px solid transparent',
  borderLeftColor: theme.palette.common.black
}))

const VerticalBar = styled('span')(({theme}) => ({
  width: '2px',
  height: SLIDER_HEIGHT,
  backgroundColor: theme.palette.common.black,
  margin: '0 2px'
}))

export const SliderThumbBox = ({children, ...other}) => {
  return (
    <SliderThumb data-testid="slider-thumb" {...other}>
      {children}
      <Box
        component={'span'}
        sx={{backgroundColor: 'white', display: 'flex', alignItems: 'center'}}
      >
        <LeftArrow />
        <VerticalBar />
        <RightArrow />
      </Box>
    </SliderThumb>
  )
}

// TODO: check how to fix this and use this in future
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SliderValueLabel(
  props: SliderValueLabelProps & {stoppage: Stoppage; translationPrefix: string}
) {
  const {children, translationPrefix} = props // stoppage, value
  const {t} = useTranslation()
  const ref = useRef(null)
  return (
    <Box>
      <Box ref={ref}>{children}</Box>
      <Box>
        {' '}
        {/* // sx={{position: 'relative', top: '-6px'}} */}
        <Popper open={true} anchorEl={ref.current} placement="top">
          <Paper sx={{p: 3, width: '212px', height: '200px'}} elevation={3}>
            {t(getTranslationKey('shiftEvent.splitStoppage.splitAt', translationPrefix))}{' '}
          </Paper>
        </Popper>
      </Box>
    </Box>
  )
}
