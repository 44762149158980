import {Iso8601} from '@hconnect/uikit'
import {DateStepper} from '@hconnect/uikit/src/lib2/components/dateTimeStepper/DateStepper'
import {TimeStepper} from '@hconnect/uikit/src/lib2/components/dateTimeStepper/TimeStepper'
import {Paper, Stack, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

export type SplitStoppageProps = {
  splitDateUtc: Iso8601
  setSplitDateUtc: (dateUtc: Iso8601) => void
  stoppageTimeRangeUtc: [Iso8601, Iso8601 | undefined]
  timezone: string
  translationPrefix?: string
}

export const StoppageSplitDateTimeEditor = ({
  stoppageTimeRangeUtc,
  timezone,
  splitDateUtc,
  setSplitDateUtc,
  translationPrefix
}: SplitStoppageProps) => {
  const {t} = useTranslation()
  const [stoppageStart, stoppageEnd] = stoppageTimeRangeUtc
  const isSameDayStoppage = moment
    .utc(stoppageStart)
    .tz(timezone)
    .isSame(moment.utc(stoppageEnd).tz(timezone), 'day')

  return (
    <Paper sx={{p: 3, width: '212px'}} elevation={8}>
      <Stack alignItems="center" gap={2}>
        <Typography variant="h5">
          {t(getTranslationKey('shiftEvent.splitStoppage.splitAt', translationPrefix))}
        </Typography>
        {!isSameDayStoppage && (
          <DateStepper
            dateUtc={splitDateUtc}
            timezone={timezone}
            dateUtcBound={[stoppageStart, stoppageEnd || moment.utc().toISOString()]}
            onChange={(dateUtc: Iso8601) => setSplitDateUtc(dateUtc)}
            data-test-id="split-date-stepper"
          />
        )}
        <TimeStepper
          dateUtc={splitDateUtc}
          timezone={timezone}
          dateUtcBound={[stoppageStart, stoppageEnd || moment.utc().toISOString()]}
          onChange={(dateUtc: Iso8601) => setSplitDateUtc(dateUtc)}
          data-test-id="split-time-stepper"
        />
      </Stack>
    </Paper>
  )
}
