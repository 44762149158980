import {dateFormatter, formatTimeZoneDate} from '@hconnect/uikit'
import type {TFunction} from 'i18next'

import {AppName, WhiteList} from '../../hproduce'
import {SERVER_TO_PRODUCT_APP_NAME_MAP} from '../consts'
import {GroupedNotifications, Notification, NotificationResponse, Translations} from '../types'

import plants from '@hconnect/common/hproduce/plants.json'

export const mapNotifications = (data: NotificationResponse[]): Notification[] => {
  return data.map((notificationResponse) => {
    const [appName, plantId] = notificationResponse.channel.split('-')
    return {
      id: notificationResponse.id,
      channel: notificationResponse.channel,
      read: notificationResponse.read,
      createdAt: new Date(notificationResponse.createdAt),
      deliverAt: new Date(notificationResponse.deliverAt),
      markdownTitle: notificationResponse.title,
      markdownContent: notificationResponse.content,
      appName: SERVER_TO_PRODUCT_APP_NAME_MAP[appName] || 'HProduce',
      plantId,
      plantName: plants[plantId]?.name || plantId,
      variables: notificationResponse.variables
    }
  })
}

export const groupNotifications = (notifications: Notification[]): GroupedNotifications => {
  const groupedNotifications: GroupedNotifications = {}

  notifications.forEach((notification) => {
    const dateKey = notification.createdAt.toDateString()
    if (!groupedNotifications[dateKey]) {
      groupedNotifications[dateKey] = []
    }
    groupedNotifications[dateKey].push(notification)
  })

  return groupedNotifications
}

export const getNotificationGroupDateLabel = (
  date: string,
  translations: Translations,
  language: string,
  t: TFunction,
  timezone?: string
) => {
  const today = new Date()
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1))

  const createdAt = new Date(date)

  if (createdAt.toDateString() === today.toDateString()) {
    return t(translations.label.today)
  } else if (createdAt.toDateString() === yesterday.toDateString()) {
    return t(translations.label.yesterday)
  } else {
    const formattedDate = timezone
      ? formatTimeZoneDate(createdAt, timezone, 'L', language)
      : dateFormatter(createdAt, language)
    return createdAt.getFullYear() === today.getFullYear()
      ? formattedDate.slice(0, -5) // remove year
      : formattedDate
  }
}

export const generateNotificationChannels = (
  whiteList?: WhiteList,
  supportedApps: AppName[] = ['Cockpit', 'easyPOM', 'HProduce', 'Predict']
) => {
  return Object.values(whiteList?.optionsPerApp || [])
    .flat()
    .filter((plantData) => supportedApps.includes(plantData.appName))
    .map((plantData) => `${plantData.appName.toLowerCase()}-${plantData.plantId}`)
}
