import {Stack, Link} from '@mui/material'
import React, {FC} from 'react'

import {RootCauseFailureAnalyses} from '../../types'
import {getMaintainAnalysisUrl} from '../../utils/analysis.utils'

type RcfaLinksProps = {
  plantId: string
  rootCauseFailureAnalyses: RootCauseFailureAnalyses[]
}

export const RcfaLinks: FC<RcfaLinksProps> = ({plantId, rootCauseFailureAnalyses}) => {
  return (
    <Stack>
      {rootCauseFailureAnalyses.map(({title, id}) => (
        <Link
          key={id}
          href={getMaintainAnalysisUrl(plantId, id)}
          underline="none"
          fontWeight={600}
          width="fit-content"
        >
          {title}
        </Link>
      ))}
    </Stack>
  )
}
