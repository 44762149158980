import {CardTitle, mergeSxs} from '@hconnect/uikit/src/lib2'
import {LinearProgress, Paper, Box, Theme, Typography, Tooltip, SxProps} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import {isString} from 'lodash'
import React, {ReactNode} from 'react'

export type ContentMode =
  | 'fullParentHeight'
  | 'max100PercentOfParentHeight'
  | 'contentDependentHeight'

type Props = {
  'data-test-id'?: string
  aboveTitle?: ReactNode
  beforeTitle?: ReactNode
  title?: ReactNode
  afterTitle?: ReactNode
  subTitle?: ReactNode
  mode: ContentMode
  minHeight?: string
  isLoading?: boolean
  errorMessage?: string
  bodyWithPadding?: boolean
  headerSx?: SystemStyleObject<Theme>
  bodySx?: SystemStyleObject<Theme>
  sx?: SxProps<Theme>
  titleTestId?: string
  children?: React.ReactNode
}

/**
 * for mode 'fullParentHeight' & 'max100PercentOfParentHeight' to work properly make sure the parent has a defined height
 */
export const ContentBox = ({mode, headerSx, bodySx, sx, ...props}: Props) => {
  return (
    <Paper
      data-test-id={props['data-test-id']}
      sx={mergeSxs(
        [
          {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            maxHeight: '100%',
            minHeight: props.minHeight,
            boxShadow: 'none'
          },
          mode === 'fullParentHeight' && {
            height: '100%'
          },
          mode === 'max100PercentOfParentHeight' && {
            flexShrink: 1,
            overflow: 'hidden'
          }
        ],
        sx
      )}
    >
      {props.aboveTitle && (
        <Box px={3} pt={2}>
          {props.aboveTitle}
        </Box>
      )}
      <Box
        sx={{
          alignItems: 'center',
          minHeight: '70px',
          px: 3,
          py: 2,
          display: 'flex',
          flexShrink: 0,
          ...headerSx
        }}
      >
        {props.beforeTitle && (
          <Box component="span" sx={{flexShrink: 0}}>
            {props.beforeTitle}
          </Box>
        )}
        <CardTitle mb={0} sx={{flexGrow: 1, overflow: 'hidden'}} data-test-id={props.titleTestId}>
          {props.title}
        </CardTitle>
        {props.afterTitle &&
          (isString(props.afterTitle) ? (
            <Tooltip title={props.afterTitle}>
              <Typography
                variant={'body2'}
                sx={{overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
              >
                {props.afterTitle}
              </Typography>
            </Tooltip>
          ) : (
            <Box component={'span'} sx={{flexShrink: 0}}>
              {props.afterTitle}
            </Box>
          ))}
      </Box>
      {props.subTitle && (
        <Box pl={3} pb={3} mt={-3}>
          {props.subTitle}
        </Box>
      )}

      {/* TODO prevent the body from jumping, when changing the loading state */}
      {props.isLoading && <LinearProgress />}

      {!props.errorMessage ? (
        <Box
          sx={[
            {
              maxHeight: '100%',
              overflowX: 'hidden',
              overflowY: 'auto'
            },
            !!props.bodyWithPadding && {
              pt: 0,
              pb: 3,
              px: 3
            },
            mode === 'fullParentHeight' && {
              flexGrow: 1
            },
            mode === 'max100PercentOfParentHeight' && {
              flexShrink: 1
            },
            !!bodySx && bodySx
          ]}
        >
          {props.children}
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
          }}
        >
          <Typography variant="h2" px={3}>
            {props.errorMessage}
          </Typography>
        </Box>
      )}
    </Paper>
  )
}
