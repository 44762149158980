import {SignalData, SensorReadings} from './types'

const sensorToAreaStatus = (areaStatus: string, sensorStatus: string) => {
  if (areaStatus === 'Ok') {
    return sensorStatus
  }

  if (sensorStatus === 'Alarm') {
    return sensorStatus
  }

  return areaStatus
}

export const countAreaState = (sensorData?: SignalData[], sensorReadings?: SensorReadings) => {
  const freqs: Record<string, string> = {}
  if (!sensorData) return freqs
  if (!sensorReadings) return freqs
  sensorData.forEach((sensor) => {
    const area = sensor?.group ?? 'Unknown area'
    freqs[area] = sensorToAreaStatus(
      freqs[area] ?? 'Ok',
      sensorReadings[sensor.localName].lastReading?.status ?? 'Ok'
    )
  })
  return freqs
}
