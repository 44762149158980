import {setProcessors, setMiddleware, LogTransaction, createLogger} from '../lib'
import {messageFormatMiddleware} from '../middleware'
import {AzureProcessor, ConsoleProcessor} from '../processors'

import {Project} from '@hconnect/common/project'

export type LogTarget = 'azure' | 'console'

/**
 *
 * @param project One of the projects existing in the HDigital Catalog
 * @param target Optional parameter if one wants to override the default logger type
 * @returns Log
 */
export const createDefaultLogger = (project: Project, target?: LogTarget): LogTransaction => {
  const logTarget = target ?? process.env.NODE_ENV === 'development' ? 'console' : 'azure'
  if (logTarget === 'console') {
    setMiddleware([messageFormatMiddleware])
    setProcessors([ConsoleProcessor])
  } else if (logTarget === 'azure') {
    setProcessors([AzureProcessor])
  }
  return createLogger({project})
}
