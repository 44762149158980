import {DifficultySummaries, QuestionDifficulty} from '../types'

export const DIFFICULTY_LABEL_KEY: Record<QuestionDifficulty, string> = {
  [QuestionDifficulty.Basic]: 'headings.basic',
  [QuestionDifficulty.Advanced]: 'headings.advanced',
  [QuestionDifficulty.Excellence]: 'headings.excellence'
}

export const QUESTION_DIFFICULTIES: QuestionDifficulty[] = [
  QuestionDifficulty.Basic,
  QuestionDifficulty.Advanced,
  QuestionDifficulty.Excellence
]

export const QUESTION_CATEGORIES_LIST: QuestionDifficulty[] = Object.values(QuestionDifficulty)

export const formatCompletion = (completion: number, fractionDigits = 1) =>
  `${completion.toFixed(fractionDigits)}%`

export const getCurrentDifficulty = ({basic, advanced}: DifficultySummaries) => {
  if (basic.answeredPositively < basic.questionCount) {
    return QuestionDifficulty.Basic
  }
  if (advanced.answeredPositively < advanced.questionCount) {
    return QuestionDifficulty.Advanced
  }
  return QuestionDifficulty.Excellence
}

export const completionScoreBandColor = (value: number): string =>
  value >= 100
    ? 'success.dark'
    : value > 80
      ? 'success.light'
      : value > 50
        ? 'warning.main'
        : 'error.light'

export const getColorFromGradient = (percentage: number) => {
  const gradient = [
    {percent: 0, color: [218, 9, 1]},
    {percent: 25, color: [229, 84, 25]},
    {percent: 50, color: [186, 145, 40]},
    {percent: 75, color: [29, 168, 82]},
    {percent: 100, color: [0, 116, 67]}
  ]

  let lowerBound, upperBound
  for (let i = 0; i < gradient.length - 1; i++) {
    if (percentage >= gradient[i].percent && percentage <= gradient[i + 1].percent) {
      lowerBound = gradient[i]
      upperBound = gradient[i + 1]
      break
    }
  }

  const lowerColor = lowerBound.color
  const upperColor = upperBound.color

  const lowerPercent = lowerBound.percent
  const upperPercent = upperBound.percent

  const range = upperPercent - lowerPercent
  const percentDelta = percentage - lowerPercent
  const color = lowerColor.map((channel: number, index: number) => {
    const channelDelta = upperColor[index] - channel
    return Math.round(channel + (percentDelta / range) * channelDelta)
  })

  return `rgb(${color.join(',')})`
}

const checkAllQuestionsDone = (
  difficultySummaries: DifficultySummaries,
  questionDifficulty: QuestionDifficulty
): boolean => {
  if (questionDifficulty === QuestionDifficulty.Advanced) {
    if (difficultySummaries.basic.answeredPositively !== difficultySummaries.basic.questionCount)
      return false
  }
  if (questionDifficulty === QuestionDifficulty.Excellence) {
    if (
      difficultySummaries.basic.answeredPositively !== difficultySummaries.basic.questionCount ||
      difficultySummaries.advanced.answeredPositively !== difficultySummaries.advanced.questionCount
    )
      return false
  }

  return !!difficultySummaries[questionDifficulty].questionCount
}

export const completionScoreBarColor = (
  difficultySummaries: DifficultySummaries,
  questionDifficulty: QuestionDifficulty
): string => {
  if (!checkAllQuestionsDone(difficultySummaries, questionDifficulty)) {
    return 'grey.300'
  }

  const answeredPositivelyTotal = QUESTION_CATEGORIES_LIST.reduce(
    (total, level) => total + difficultySummaries[level].answeredPositively,
    0
  )
  const questionCountTotal = QUESTION_CATEGORIES_LIST.reduce(
    (total, level) => total + difficultySummaries[level].questionCount,
    0
  )

  const percentage = (answeredPositivelyTotal / questionCountTotal) * 100

  return getColorFromGradient(percentage)
}
