import {Box, Theme, Typography} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

export const Tag: React.FC<{
  labelKey: string
  label?: string
  descriptionSx?: SystemStyleObject<Theme>
  'data-test-id'?: string
  translationPrefix?: string
  children: React.ReactNode
}> = ({children, labelKey, label, descriptionSx = {}, translationPrefix, ...props}) => {
  const {t} = useTranslation()
  return (
    <Box
      key={labelKey}
      sx={{
        width: '100%',
        whiteSpace: 'pre-line'
      }}
      data-test-id={props['data-test-id']}
    >
      <Typography variant="caption">
        {label || t(getTranslationKey(labelKey, translationPrefix))}
      </Typography>
      <Typography
        variant="h4"
        color="textPrimary"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: 16,
          fontWeight: 500,
          ...descriptionSx
        }}
      >
        {children || t(getTranslationKey('shiftEvent.action.noneOfThat', translationPrefix))}
      </Typography>
    </Box>
  )
}
