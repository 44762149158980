import {ViewMode} from '@hconnect/common/components/eventsList/types'

export const modeAfterClose = <T extends {id: string}>(
  original?: T,
  editVersion?: T
): ViewMode<T> => {
  // Here we can handle the "are you sure you don't want to save"

  if (original) {
    // 'editMode'
    return {mode: 'detailsMode', itemId: original.id}
  } else if (!original && editVersion) {
    // 'createMode'
    return {mode: 'tableOnlyMode'}
  } else {
    // 'detailsMode'
    return {mode: 'tableOnlyMode'}
  }
}
