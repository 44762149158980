import {Box} from '@mui/material'
import {AxiosError} from 'axios'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentExpertSystemUsage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {ContentBox} from '../ContentBox'

import {ExpertSystemEquipmentCharts} from './ExpertSystemEquipmentCharts'

type ExpertSystemWrapperProps = {
  isSameMonth: boolean
  isLoading: boolean
  error: AxiosError | null
  expertSystemEquipments?: EquipmentExpertSystemUsage[]
  translationPrefix?: string
}

export const ExpertSystemWrapper: FC<ExpertSystemWrapperProps> = ({
  isSameMonth,
  error,
  expertSystemEquipments,
  translationPrefix,
  isLoading
}) => {
  const {t} = useTranslation()

  return !isSameMonth || error || !expertSystemEquipments?.length ? (
    <ContentBox
      title={t(getTranslationKey('expertSystemUtilization.title', translationPrefix))}
      mode="contentDependentHeight"
      bodyWithPadding
      data-test-id="expert-system-section"
    >
      {error && (
        <Box component="span" data-test-id="expert-system-error-label">
          {t(getTranslationKey('expertSystemUtilization.error', translationPrefix))}
        </Box>
      )}
      {!error && isSameMonth && !expertSystemEquipments?.length && (
        <Box component="span" data-test-id="expert-system-empty-label">
          {t(getTranslationKey('expertSystemUtilization.noData', translationPrefix))}
        </Box>
      )}
      {!error && !isSameMonth && (
        <Box component="span" data-test-id="expert-system-unavailable-label">
          {t(getTranslationKey('expertSystemUtilization.unavailable', translationPrefix))}
        </Box>
      )}
    </ContentBox>
  ) : (
    <ExpertSystemEquipmentCharts
      expertSystemEquipments={expertSystemEquipments}
      translationPrefix={translationPrefix}
      isLoading={isLoading}
    />
  )
}
