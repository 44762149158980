import React from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'
import {AddFilterButton, type Option} from '../filters'

type AddFilterProps<T> = {
  onChange: (newValue: string[]) => void
  'data-test-id': string
  values: string[]
  options: T[]
  translationPrefix?: string
}

export function AddFilter<T extends string>({
  values,
  onChange,
  options,
  'data-test-id': dataTestId,
  translationPrefix
}: AddFilterProps<T>) {
  const {t} = useTranslation()

  const dropDownOptions: Option<T>[] = React.useMemo(
    () =>
      options.map((option) => ({
        value: option,
        label: t(getTranslationKey(`shiftEvent.addFilter.${option}`, translationPrefix))
      })),
    [t, options, translationPrefix]
  )

  return (
    <AddFilterButton
      data-test-id={dataTestId}
      id="events-filter-bar-add-filter-button"
      buttonLabel={t(getTranslationKey('shiftEvent.label.addFilter', translationPrefix))}
      options={dropDownOptions}
      values={values}
      onChange={onChange}
    />
  )
}
