import React from 'react'

import {Translations} from '../types'

type TranslationKeyContext = {
  translations: Translations
}
type TranslationKeyProps = TranslationKeyContext & {
  children: React.ReactNode
}

export const TranslationKeyContext = React.createContext<TranslationKeyContext | undefined>(
  undefined
)
TranslationKeyContext.displayName = 'TranslationKeyContext'

export const TranslationKeyProvider: React.FC<TranslationKeyProps> = ({translations, children}) => {
  return (
    <TranslationKeyContext.Provider value={{translations}}>
      {children}
    </TranslationKeyContext.Provider>
  )
}

export const useTranslationKey = () => {
  const context = React.useContext(TranslationKeyContext)
  if (context === undefined) {
    throw new Error('useTranslationKey must be used within a TranslationKeyProvider')
  }
  return context
}
