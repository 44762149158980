import {messageFormatMiddleware} from '../middleware'

import type {MiddlewareFn} from './Log'

export const defaultMiddleware = [messageFormatMiddleware]

let middleware: Set<MiddlewareFn> = new Set(defaultMiddleware)
/**
 * Replaces the middleware that are called before each log level is processed.
 */

export const setMiddleware = (newMiddleware: MiddlewareFn[]): void => {
  middleware = new Set(newMiddleware)
}
/**
 * Adds a new middleware function to the existing set of middleware (which is by default empty). Returns the length of all currently used middleware
 */
export const addMiddleware = (newMiddleware: MiddlewareFn[]): void =>
  // eslint-disable-next-line @typescript-eslint/unbound-method
  newMiddleware.forEach(middleware.add, middleware)

export const getMiddleware = (): MiddlewareFn[] => Array.from(middleware)
