import {CircularProgress, Link, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HROC_CHECKLIST_KIND_TRANSLATION_KEYS} from '../../consts'
import {AppName} from '../../hproduce/types'
import {TaskChecklist} from '../../types'
import {getPlantChecklistUrl} from '../../utils/checklists.utils'
import {getTranslationKey} from '../../utils/translation.utils'

import {ChecklistStatusChip} from './ChecklistStatusChip'

type RefererParams = {
  appName: AppName
  pathname: string
  search?: string
}

type Props = {
  checklists: TaskChecklist[]
  isLoading: boolean
  referer: RefererParams
  plantId: string
  translationPrefix?: string
}

const EMPTY_PLACEHOLDER = '-'

export const EventTaskChecklists = ({
  checklists,
  isLoading,
  referer,
  plantId,
  translationPrefix = ''
}: Props) => {
  if (isLoading) {
    return <CircularProgress size={20} />
  }
  if (!checklists?.length) {
    return <>{EMPTY_PLACEHOLDER}</>
  }

  return (
    <Stack spacing={2}>
      {checklists.map((checklist) => (
        <ChecklistItem
          key={checklist.id}
          checklists={checklist}
          translationPrefix={translationPrefix}
          referer={referer}
          plantId={plantId}
        />
      ))}
    </Stack>
  )
}

const ChecklistItem = ({
  checklists,
  referer,
  plantId,
  translationPrefix
}: {
  checklists: TaskChecklist
  referer: RefererParams
  plantId: string
  translationPrefix: string
}) => {
  const {t} = useTranslation()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Link href={getPlantChecklistUrl({plantId, checklistId: checklists.id, referer})}>
        {t(
          getTranslationKey(
            HROC_CHECKLIST_KIND_TRANSLATION_KEYS[checklists.kind],
            translationPrefix
          )
        )}
      </Link>
      <ChecklistStatusChip
        status={checklists.status}
        translationPrefix={translationPrefix}
        size="small"
      />
    </Stack>
  )
}
