import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

import {FilterActionButton} from './FilterActionButton'

type SelectAllFilterButtonProps = {
  onClick: () => void
  keyName: string
  translationPrefix?: string
}

export const SelectAllFilterButton: React.FC<SelectAllFilterButtonProps> = ({
  onClick,
  keyName,
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <FilterActionButton
      sx={{
        marginTop: '10px'
      }}
      variant="text"
      onClick={onClick}
      startIcon={<CheckIcon />}
      data-test-id={`select-all-filter-button-${keyName}`}
    >
      {t(getTranslationKey('shiftEvent.label.selectAll', translationPrefix))}
    </FilterActionButton>
  )
}
