import {useState} from 'react'

import {useFile} from '../hooks/useFile'
import {AttachmentFile, Attachment} from '../types'

export const attachmentToFile = (input: Attachment): AttachmentFile => {
  // called before EventEdit unmount
  const {data} = useFile(input.previewUrl ?? input.url, input.mediaType, input.id, {
    onSuccess: (response) => {
      setSource(response ? URL.createObjectURL(new Blob([response])) : '')
    }
  })
  const [source, setSource] = useState<string>(data ? URL.createObjectURL(new Blob([data])) : '')

  return {
    id: input.id,
    name: input.fileName,
    contentType: input.mediaType,
    preview: source,
    isImage: input.mediaType.includes('image')
  }
}

export const getUniqueAttachments = (
  attachments: Attachment[] | undefined,
  maxAllowedFiles: number
) => {
  return attachments
    ? [...new Map(attachments.map((o) => [o.fileName, o])).values()].slice(0, maxAllowedFiles)
    : []
}
