import {useCallback} from 'react'
import {useLocalStorage} from 'react-use'

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type ExpandedGroupIds = 'all' | string

export const useExpandedGroups = (
  allGroups: string[],
  storageKey: string
): [string[], (id: ExpandedGroupIds) => void] => {
  const [openGroups, setGroups] = useLocalStorage<string[]>(storageKey, [])

  const toggle = useCallback(
    (id: ExpandedGroupIds) => {
      let next: string[]

      if (!openGroups || !allGroups) {
        return
      }

      if (id === 'all') {
        next = openGroups.length === allGroups.length ? [] : [...allGroups]
      } else if (openGroups.includes(id)) {
        next = openGroups.filter((groupName) => groupName !== id)
      } else {
        next = [...openGroups, id]
      }

      setGroups(next)
    },
    [openGroups, allGroups, setGroups]
  )
  return [openGroups ?? [], toggle]
}
