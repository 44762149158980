import {useNotification, replaceNonWordCharacters} from '@hconnect/uikit'
import {Slideshow, InsertDriveFile, PictureAsPdf} from '@mui/icons-material'
import {Grid, Typography, Box, Theme} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import React, {ElementType} from 'react'
import {useTranslation} from 'react-i18next'
import {Document, Page, pdfjs} from 'react-pdf/dist/esm/entry.webpack'

// React-pdf library specifies a list of workers to directly import.
// The one imported here is specifically for webpack v4
// You can find a list of possible imports in the documentation @ https://github.com/wojtekmaj/react-pdf#configure-pdfjs-worker
// For sample usage, please check the samples folder in the library repository @ https://github.com/wojtekmaj/react-pdf/tree/main/sample

type IconOrPreview = 'icon' | 'preview'

const BASE_HEIGHT = '110px'
export const imageFluidSx = {
  height: BASE_HEIGHT,
  maxWidth: '330px',
  overflowX: 'hidden',
  marginLeft: 2,
  marginBottom: 1,
  objectFit: 'contain',
  overflowY: 'hidden'
}

const fullWidthSx = {
  height: '100vh',
  maxHeight: '100vh',
  padding: '2vmax'
}

const clickableSx = {
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7
  }
}

const MEDIA_TYPE_ICON_MAP: Record<string, ElementType> = {
  'application/pdf': PictureAsPdf,
  'application/vnd.ms-excel': InsertDriveFile,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': InsertDriveFile,
  'video/mp4': Slideshow,
  'video/3gp': Slideshow,
  'video/mov': Slideshow
}

const isAttachmentTypePreviewNotSupported = (mediaType: string) =>
  mediaType.includes('video') || mediaType.includes('sheet') || mediaType.includes('excel')

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
export const AttachmentHandler: React.FC<{
  file?: string | null
  fullWidth?: boolean
  alt: string
  iconOrPreview: IconOrPreview
  mediaType: string
  sx?: SystemStyleObject<Theme>
  clickable?: boolean
}> = ({file, alt, iconOrPreview, mediaType, fullWidth, sx, clickable}) => {
  const [numPages, setNumPages] = React.useState<number>(0)
  const {notify} = useNotification()
  const {t} = useTranslation()

  function onDocumentLoadSuccess({numPages: nextNumPages}: {numPages: number}) {
    setNumPages(nextNumPages)
  }
  if (mediaType.includes('image')) {
    return (
      <span data-test-id="event-attachment-wrapper">
        {file && (
          <Box
            component="img"
            key={file}
            src={file}
            data-test-id={`event-file-${replaceNonWordCharacters(alt)}`}
            alt={alt}
            sx={[!!sx && sx, !!fullWidth && fullWidthSx, !!clickable && clickableSx]}
          />
        )}
      </span>
    )
  } else if (iconOrPreview === 'preview' && isAttachmentTypePreviewNotSupported(mediaType)) {
    return (
      <Box
        sx={(theme) => ({
          color: theme.palette.primary.contrastText,
          height: '100vh',
          display: 'flex',
          alignItems: 'center'
        })}
      >
        {t('previewUnavailable')}
      </Box>
    )
  } else {
    const Icon: ElementType = MEDIA_TYPE_ICON_MAP[mediaType] || InsertDriveFile
    return (
      <span data-test-id="event-attachment-wrapper">
        {file && [
          iconOrPreview === 'preview' ? (
            <Box>
              <Document
                key={file}
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error: Error) =>
                  notify('warning', `${t('error.failedToLoadFilePreview')} ${error.message}}`)
                }
                onSourceError={(error: Error) =>
                  notify('warning', `${t('error.failedToLoadFileSource')} ${error.message}}`)
                }
                renderMode="svg"
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </Box>
          ) : (
            <Grid item key={file} sx={imageFluidSx}>
              <Typography variant="caption">{alt}</Typography>
              <Typography>
                <Icon
                  data-test-id={`event-file-${replaceNonWordCharacters(alt)}`}
                  fontSize="large"
                />
              </Typography>
            </Grid>
          )
        ]}
      </span>
    )
  }
}
