import {mergeSx} from '@hconnect/uikit/src/lib2'
import {IconButton, IconButtonProps} from '@mui/material'
import React, {FC, forwardRef} from 'react'

export const FormIconButton: FC<IconButtonProps> = forwardRef(({children, sx, ...props}, ref) => {
  return (
    <IconButton
      sx={mergeSx(
        (theme) => ({
          borderRadius: 1,
          border: 1,
          borderColor: theme.palette.grey[200],
          width: 58,
          height: 58,
          boxShadow: theme.shadows[1]
        }),
        sx
      )}
      {...props}
      ref={ref}
    >
      {children}
    </IconButton>
  )
})
