import {Column, DataTable, ExpandableListItem} from '@hconnect/uikit/src/lib2'
import {Stack, Typography} from '@mui/material'
import React, {FC, ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {ExpandedGroupIds} from '../../hooks/useExpandGroups'
import {ManualKpiToUpdate, ManualKpisProcessStage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

type ManualKpiEditProps = {
  isProcessStagesLoading: boolean
  processStages: ManualKpisProcessStage[]
  manualKpiList: ManualKpiToUpdate[]
  columns: Column<ManualKpiToUpdate>[]
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  getExpandableListItemAction?: (code: string, name: string) => ReactNode
  getSectionBottomAction?: (code: string) => ReactNode
  translationPrefix?: string
}

export const ManualKpiWrapper: FC<ManualKpiEditProps> = ({
  isProcessStagesLoading,
  processStages,
  manualKpiList,
  getExpandableListItemAction,
  getSectionBottomAction,
  columns,
  openProcessStages,
  setOpenProcessStages,
  translationPrefix
}) => {
  const {t} = useTranslation()

  return (
    <>
      {processStages.map(({name, code}, index) => {
        const isOpen = openProcessStages.includes(code)
        const kpis: ManualKpiToUpdate[] = manualKpiList.filter((kpi) => kpi.processStage === code)
        return (
          <ExpandableListItem
            key={code}
            isOpen={isOpen}
            id={code}
            keepMounted={true}
            action={getExpandableListItemAction?.(code, name)}
            toggleState={(id) => setOpenProcessStages(id)}
            label={
              <Stack>
                <Typography variant="h4">{name}</Typography>
                <Typography variant="caption">{code}</Typography>
              </Stack>
            }
            content={
              <Stack>
                <DataTable<ManualKpiToUpdate>
                  columns={columns}
                  data={kpis}
                  keyExtractor={(kpi) => `${code}-${kpi.name}`}
                  emptyMessage={
                    (!isProcessStagesLoading &&
                      t(getTranslationKey('performance.label.noData', translationPrefix))) ??
                    ''
                  }
                  rowSx={() => ({
                    height: 56,
                    ...(index === processStages.length - 1 &&
                      !getSectionBottomAction && {
                        '&:last-child td': {
                          borderBottom: 'none'
                        }
                      })
                  })}
                />
                {getSectionBottomAction?.(code)}
              </Stack>
            }
            showDivider={true}
            data-test-id={`performance-process-stage-${code}`}
          />
        )
      })}
    </>
  )
}
