import React from 'react'

export const ProcessStageTitle: React.FC<{stages?: Map<string, string>; stage?: string}> = ({
  stages,
  stage
}) => {
  if (stage && stages && stages.has(stage)) {
    return <>{stages.get(stage)}</>
  }

  return <>{stage || ''}</>
}
