import {AppLogo, timeFormatter} from '@hconnect/uikit'
import {MarkdownText} from '@hconnect/uikit/src/lib2'
import {alpha, Box, Card, Link, Typography} from '@mui/material'
import moment from 'moment-timezone'
import React, {FC, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {Notification} from '../../types'

type NotificationCardProps = {
  notification: Notification
  timezone?: string
  onClick?: () => Promise<void>
  actions?: ReactNode
  'data-test-id'?: string
}

const BORDER_SPACING = 0.5

export const NotificationCard: FC<NotificationCardProps> = ({
  timezone,
  actions,
  onClick,
  notification: {
    markdownTitle,
    markdownContent,
    read,
    createdAt,
    appName,
    plantName,
    variables: {redirectActionUrl}
  },
  'data-test-id': dataTestId
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  const time = useMemo(() => {
    const utcOffset = timezone
      ? moment(createdAt).tz(timezone).utcOffset()
      : moment(createdAt).utcOffset()
    return timeFormatter(createdAt, language, utcOffset)
  }, [createdAt, language, timezone])

  return (
    <Card
      onClick={onClick}
      component={Link}
      href={redirectActionUrl}
      sx={(theme) => ({
        py: 2,
        pr: 3,
        textDecoration: 'none',
        pl: read ? 3 : 3 - BORDER_SPACING,
        boxShadow: 'none',
        borderRadius: 0,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: read
          ? 'none'
          : `${theme.spacing(BORDER_SPACING)} solid ${theme.palette.primary.main}`,
        backgroundColor: read ? 'unset' : alpha(theme.palette.primary.main, 0.04),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, read ? 0.04 : 0.08)
        },
        '&:active': {
          backgroundColor: alpha(theme.palette.primary.main, read ? 0.08 : 0.12)
        }
      })}
      data-test-id={dataTestId}
    >
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box>
          <Box display="flex" gap={0.5}>
            <AppLogo name={appName} size={16} />
            <Typography variant="caption">
              {plantName} {time}
            </Typography>
          </Box>
          <Typography variant="h4" fontSize={16}>
            <MarkdownText>{markdownTitle}</MarkdownText>
          </Typography>
        </Box>
        {actions}
      </Box>

      <MarkdownText>{markdownContent}</MarkdownText>
    </Card>
  )
}
