const BASE_DIMENSION = 110
const BASE_WIDTH = BASE_DIMENSION
export const BASE_HEIGHT = BASE_DIMENSION

export const clickableSx = () => ({
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7
  }
})

export const imageBoxSx = () => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: -2,
  marginTop: 2,
  '& $spinner': {
    height: BASE_HEIGHT,
    width: BASE_WIDTH
  }
})

export const headlineSx = () => ({
  mt: 3,
  mb: 1
})
