import {AxiosError} from 'axios'
import {useMutation, UseMutationOptions, useQueryClient} from 'react-query'

import {useApi} from './useApi'

export const useEventDelete = (
  plantId: string,
  options?: UseMutationOptions<unknown, AxiosError, string>
) => {
  const {publicApi} = useApi()
  const queryClient = useQueryClient()
  return useMutation<unknown, AxiosError, string>(
    async (eventId: string) => {
      const path = `/shifthandover/${plantId}/events/${eventId}`
      const response = await publicApi.delete(path)
      return response.data
    },
    {
      // allow the caller to override the default options
      ...options,

      // make sure to invalidate the Queries after the regular onSuccess process is done
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries()
        await options?.onSuccess?.(data, variables, context)
      }
    }
  )
}
