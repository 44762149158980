import {Check as CheckIcon, PlayCircleOutline as PlayCircleOutlineIcon} from '@mui/icons-material'
import {Chip, ChipOwnProps} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {HROC_CHECKLIST_STATUS_TRANSLATION_KEYS} from '../../consts'
import {ChecklistStatus} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

export const ChecklistStatusChip = ({
  status,
  translationPrefix,
  size
}: {
  status: ChecklistStatus
  translationPrefix: string
  size: ChipOwnProps['size']
}) => {
  const {t} = useTranslation()

  return (
    <Chip
      label={t(
        getTranslationKey(HROC_CHECKLIST_STATUS_TRANSLATION_KEYS[status], translationPrefix)
      )}
      color={getChecklistStatusColor(status)}
      icon={getChecklistStatusIcon(status)}
      variant="outlined"
      size={size}
    />
  )
}

const getChecklistStatusColor = (status: ChecklistStatus) => {
  switch (status) {
    case ChecklistStatus.PENDING:
      return 'default'
    case ChecklistStatus.DONE:
      return 'success'
    default:
      return undefined
  }
}

const getChecklistStatusIcon = (status: ChecklistStatus) => {
  switch (status) {
    case ChecklistStatus.PENDING:
      return <PlayCircleOutlineIcon />
    case ChecklistStatus.DONE:
      return <CheckIcon />
    default:
      return undefined
  }
}
