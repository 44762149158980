import {ConfirmButton, CancelButton} from '@hconnect/uikit/src/lib2'
import {Box, TextField} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../utils/translation.utils'

import {MAX_COMMENT_LENGTH} from './comments.consts'

interface CommentsFormProps {
  onCancel: () => void
  onSave: (value: string) => void
  translationPrefix?: string
}

export const CommentForm: React.FC<CommentsFormProps> = ({onCancel, onSave, translationPrefix}) => {
  const {t} = useTranslation()
  const [commentValue, setCommentValue] = useState('')

  return (
    <Box display={'flex'} flexDirection={'column'} pt={1} pb={2}>
      <TextField
        variant={'filled'}
        label={t(getTranslationKey('comments.label', translationPrefix))}
        multiline
        value={commentValue}
        inputProps={{
          maxLength: MAX_COMMENT_LENGTH,
          'data-test-id': 'comment-form-comment-field-input'
        }}
        onChange={(event) => {
          setCommentValue(event.target.value)
        }}
        data-test-id={'comment-form-comment-field'}
      />
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} pt={1.5}>
        <CancelButton
          variant="outlined"
          onClick={onCancel}
          data-test-id="comment-cancel-btn"
          sx={{mr: 1.5}}
        >
          {t(getTranslationKey('button.cancel', translationPrefix))}
        </CancelButton>
        <ConfirmButton
          onClick={() => {
            onSave(commentValue.trim())
          }}
          disabled={!commentValue.trim()}
          data-test-id="comment-save-btn"
        >
          {t(getTranslationKey('button.save', translationPrefix))}
        </ConfirmButton>
      </Box>
    </Box>
  )
}
