import moment from 'moment-timezone'

import {
  EquipmentPerformance,
  EquipmentPerformanceDto,
  KpiAssignmentDto,
  ManualKpi,
  ManualKpiDto
} from '../types'

export const mapManualKpis = (manualKpis: ManualKpiDto[], timezone: string): ManualKpi[] =>
  manualKpis.map((manualKpi: ManualKpiDto) => ({
    ...manualKpi,
    createdAt: moment.utc(manualKpi.createdAt).tz(timezone),
    updatedAt: moment.utc(manualKpi.updatedAt).tz(timezone),
    timestamp: moment.utc(manualKpi.timestamp).tz(timezone)
  }))

export const mapEquipmentPerformances = (
  data: EquipmentPerformanceDto[],
  timezone: string
): EquipmentPerformance[] =>
  data.map((item) => ({
    ...item,
    kpiPerformance: item.kpiPerformance && {
      kpiAssignments:
        item.kpiPerformance.kpiAssignments &&
        item.kpiPerformance.kpiAssignments.map((assignment: KpiAssignmentDto) => ({
          ...assignment,
          result: assignment.result && {
            ...assignment.result,
            startTime: moment(assignment.result.startTime).tz(timezone),
            endTime: moment(assignment.result.endTime).tz(timezone)
          }
        }))
    }
  }))
