import {timeFormatter} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {DateTime} from '../../types'

export const TimeFormat: React.FC<{date: DateTime; timezone: string}> = ({date, timezone}) => {
  const {i18n} = useTranslation()
  const utcOffset = moment(date).tz(timezone).utcOffset()
  return <>{timeFormatter(date, i18n.language, utcOffset)}</>
}
