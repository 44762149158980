import type {Permission, PermissionType} from '@hconnect/apiclient'
import type MockAdapter from 'axios-mock-adapter/types'

import type {MockStorage} from './MockStorage'

export class MockPermissions {
  constructor(private storage: MockStorage) {}

  private current() {
    return this.storage.load().permissions
  }

  /**
   *
   * @param userId The userId for which we add a new permission
   * @param permissionType The permissionType
   * @param permissionOverride Additional properties that will be added to the Permission object; if this is not given,
   * we use sensible defaults.
   */
  addPermission(
    userId: string | {id: string},
    permissionType: PermissionType,
    permissionOverride?: Partial<Permission>
  ) {
    const id = typeof userId === 'string' ? userId : userId.id
    const current = this.storage.load()
    const newPermission = {
      ...this.createDefaultDfApiPermission(id, permissionType),
      ...permissionOverride
    }
    current.permissions = {
      ...current.permissions,
      [id]: [...this.getPermissions(id), newPermission]
    }
    this.storage.save(current)
  }

  /**
   *
   * @param userId The user id for which the permission is to be removed
   * @param permissionType The permission type that should be removed
   * @param predicate An additional optional predicate function to decide if we really would want to remove that
   * permission - This is important as we have identical permissionType but different dataScopes (i.e. country). Using
   * this predicate function you can decide if a permission should be removed based additional properties. If the
   * predicate returns true and the permissionType matches, the permission will be removed.
   */
  removePermission(
    userId: string,
    permissionType: PermissionType,
    predicate: (p: Permission) => boolean = () => true
  ) {
    const current = this.storage.load()
    current.permissions[userId] = this.getPermissions(userId).filter(
      (p) => !(p.permissionType === permissionType && predicate(p))
    )
    this.storage.save(current)
  }

  getPermissions(userId: string): Permission[] {
    return this.current()[userId] || []
  }

  registerDfResponseForAxios(mockAdapter: MockAdapter) {
    mockAdapter.onGet(/\/permissions\?userId=.+/i).reply((config) => {
      if (!config.url) return [500, undefined]
      const userId = config.url.split('=')[1]
      const permissions = this.getPermissions(userId)
      return [200, permissions]
    })

    mockAdapter.onGet(/\/permissions/i).reply((config) => {
      const userId = config.params?.userId
      if (!userId || typeof userId !== 'string') return [500, undefined]
      return [200, this.getPermissions(userId)]
    })
  }

  private createDefaultDfApiPermission(userId: string, permissionType: PermissionType): Permission {
    return {
      userId,
      connector: 'EUNWG',
      permissionType,
      dataScope: '{}',
      expiryDate: '2040-01-01T13:00:00.000Z'
    }
  }
}
