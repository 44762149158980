export const MAX_ALLOWED_ATTACHMENTS = 10
export const FILE_MAX_SIZE_IN_BYTES = 104857600
export enum FileErrorCode {
  INVALID_TYPE,
  TOO_LARGE
}
export const FILE_REJECTED_TRANSLATIONS: Record<FileErrorCode, string> = {
  [FileErrorCode.TOO_LARGE]: 'dropzone.filesRejected.tooLarge',
  [FileErrorCode.INVALID_TYPE]: 'dropzone.filesRejected.type'
}
