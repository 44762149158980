import {Box, CircularProgress, Divider, Stack, Typography} from '@mui/material'
import {AxiosError} from 'axios'
import {isEmpty} from 'lodash'
import React, {Fragment, FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationKey} from '../../hooks'
import {GroupedNotifications, Notification} from '../../types'
import {getNotificationGroupDateLabel} from '../../utils'
import {NotificationActions} from '../notification-actions'
import {NotificationCard} from '../notification-card'

import LoadingDeliverySvg from './NotificationsEmpty.svg'

type NotificationDropdownContentProps = {
  error: AxiosError | null
  isLoading: boolean
  groupedNotifications: GroupedNotifications
  onNotificationClick: (notification: Notification) => Promise<void>
  hasNextPage: boolean
  refetchNotifications: (
    omitLoadingState?: boolean,
    notificationIdToRefresh?: string
  ) => Promise<void>
  timezone?: string
}

export const NotificationDropdownContent: FC<NotificationDropdownContentProps> = ({
  error,
  isLoading,
  groupedNotifications,
  timezone,
  onNotificationClick,
  hasNextPage,
  refetchNotifications
}) => {
  const {i18n, t} = useTranslation()
  const {translations} = useTranslationKey()

  if (error) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex={1}
        data-test-id="notification-dropdown-error-container"
      >
        <Typography>{t(translations.label.error)}</Typography>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex={1}
        data-test-id="notification-dropdown-loading-container"
      >
        <CircularProgress />
      </Box>
    )
  }

  if (isEmpty(groupedNotifications)) {
    return (
      <Stack
        display="flex"
        mt={3}
        mb={5}
        alignItems="center"
        data-test-id="notification-dropdown-empty-container"
      >
        <Box component="img" maxWidth={240} src={LoadingDeliverySvg} />
        <Typography
          variant="body1"
          textAlign="center"
          fontStyle="italic"
          color="text.secondary"
          mt={3}
        >
          {t(translations.label.empty)}
        </Typography>
      </Stack>
    )
  }

  return (
    <Box>
      {Object.entries(groupedNotifications).map(([date, notifications]) => (
        <Stack key={date}>
          <Typography
            variant="caption"
            sx={{
              backgroundColor: 'grey.100',
              px: 1.5,
              py: 1,
              mx: 1.5,
              borderRadius: 0.5
            }}
          >
            {getNotificationGroupDateLabel(date, translations, i18n.language, t, timezone)}
          </Typography>
          {notifications.map((notification, index) => (
            <Fragment key={`${notification.appName}_${index}`}>
              <NotificationCard
                notification={notification}
                timezone={timezone}
                onClick={async () => await onNotificationClick(notification)}
                actions={
                  <NotificationActions
                    refetchNotifications={refetchNotifications}
                    notificationId={notification.id}
                  />
                }
                data-test-id={`notification-card-${index}`}
              />
              <Divider sx={{mx: 3}} />
            </Fragment>
          ))}
        </Stack>
      ))}
      {hasNextPage && (
        <Box
          display="flex"
          justifyContent="center"
          my={2}
          data-test-id="notification-dropdown-loading-page-container"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
