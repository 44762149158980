import type {Permission, Role, User} from '@hconnect/apiclient'

export type AppStage = 'qa' | 'prod' | 'uat' | 'uatr' | 'regression'

export interface MockStorageData<TScenarioData = Record<string, unknown>> {
  mockEnabled: boolean
  appStage?: AppStage
  appName?: string

  users: User[]
  permissions: Record<string, Permission[]>
  roles: Record<string, Role[]>
  scenario: TScenarioData | undefined
}

/**
 * Small wrapper around browser storage to store our mocking data
 */

export class MockStorage<TScenarioData = Record<string, unknown>> {
  private browserStorage: Storage

  constructor(
    public readonly storageKey: string,
    browserStorage?: Storage
  ) {
    const isCypressRunner = typeof process.env.REACT_APP_CLIENT_NAME === 'undefined'
    const isCypressBrowser = 'Cypress' in window

    this.browserStorage =
      browserStorage || isCypressBrowser || isCypressRunner
        ? window.localStorage
        : window.sessionStorage

    const hasDataSaved = this.browserStorage.getItem(this.storageKey) !== null
    if (!hasDataSaved) {
      this.writeDefault()
    }
  }

  save(data: MockStorageData<TScenarioData>) {
    this.browserStorage.setItem(this.storageKey, JSON.stringify(data))
  }

  load(): MockStorageData<TScenarioData> {
    const jsonString = this.browserStorage.getItem(this.storageKey)
    if (jsonString === null) return this.writeDefault()
    return JSON.parse(jsonString) as MockStorageData<TScenarioData>
  }

  reset() {
    this.clear()
    this.writeDefault()
  }

  clear() {
    this.browserStorage.removeItem(this.storageKey)
  }

  writeDefault() {
    const defaultData = {
      mockEnabled: false,
      appStage: undefined,
      appName: undefined,
      permissions: {},
      roles: {},
      users: [],
      scenario: undefined
    }
    this.save(defaultData)
    return defaultData
  }
}
