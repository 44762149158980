import {MoreVert} from '@mui/icons-material'
import {IconButton, Menu} from '@mui/material'
import React, {useCallback, useRef, useState, FC, MouseEvent} from 'react'

import {useDeleteNotification} from '../../hooks'

import {RemoveActionItem} from './remove-action-item'

type NotificationActions = {
  notificationId: string
  refetchNotifications: (
    omitLoadingState?: boolean,
    notificationIdToRefresh?: string
  ) => Promise<void>
}

export const NotificationActions: FC<NotificationActions> = ({
  notificationId,
  refetchNotifications
}) => {
  const menuButtonRef = useRef(null)

  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [isRemoveMode, setIsRemoveMode] = useState<boolean>(false)
  const openMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      setMenuOpen(true)
    },
    [setMenuOpen]
  )

  const deleteNotificationData = useDeleteNotification(() => {
    void refetchNotifications()
    closeMenu()
  })
  const {clearState} = deleteNotificationData

  const closeMenu = useCallback(
    (event?: MouseEvent) => {
      event?.preventDefault()
      setMenuOpen(false)
      // wait to clear states after the closing dropdown animation
      setTimeout(() => {
        clearState()
        setIsRemoveMode(false)
      }, 150)
    },
    [clearState]
  )

  return (
    <>
      <IconButton
        color="primary"
        ref={menuButtonRef}
        onClick={openMenu}
        sx={{minWidth: 38}}
        data-test-id="notification-dropdown-more"
      >
        <MoreVert />
      </IconButton>
      <Menu
        data-test-id="notification-actions-dropdown"
        anchorEl={menuButtonRef.current}
        open={menuOpen}
        onClose={(e) => closeMenu(e as MouseEvent)}
        MenuListProps={{sx: {py: 0}}}
      >
        <RemoveActionItem
          notificationId={notificationId}
          deleteNotificationData={deleteNotificationData}
          onCancel={closeMenu}
          isRemoveMode={isRemoveMode}
          enableRemoveMode={() => setIsRemoveMode(true)}
        />
      </Menu>
    </>
  )
}
