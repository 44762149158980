import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import DashboardIcon from '@mui/icons-material/Dashboard'
import {Button, Link} from '@mui/material'
import React from 'react'
import {generatePath} from 'react-router'

import {PLANT_DOWNTIME} from '../../consts'
import {hproduce} from '../../hproduce/config'
import {getHost} from '../../utils/route.utils'

const generateDowntimeDashboardLink = (plantId: string) => {
  return generatePath(getHost(hproduce.url) + PLANT_DOWNTIME, {plantId})
}

type StyleVariant = 'onBlue' | 'onWhite'

type Props = {
  plantId: string
  title: string
  variant?: 'text' | 'outlined' | 'contained'
  styleVariant?: StyleVariant
  fullWidth?: boolean
  onClick?: () => void
}

export const DashboardLink: React.FC<Props> = ({
  plantId,
  title,
  variant,
  styleVariant = 'onWhite',
  fullWidth = false,
  onClick
}) => {
  const isOnBlue = styleVariant === 'onBlue'

  return (
    <Link
      sx={{textDecoration: 'none'}}
      href={generateDowntimeDashboardLink(plantId)}
      data-test-id="dashboard-link"
    >
      <Button
        variant={variant ?? 'text'}
        startIcon={<DashboardIcon />}
        sx={{
          width: fullWidth ? '100%' : '200px',
          height: '58px',
          color: isOnBlue ? 'white' : 'primary',
          borderColor: isOnBlue ? customThemeConstants().palette.mediumInputBorder : 'primary'
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    </Link>
  )
}
