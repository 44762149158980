import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useApi} from './useApi'

export const QueryKey = 'events-files'

export const useFile = (
  url: string,
  contentType: string,
  fileId?: number,
  options?: UseQueryOptions<ArrayBuffer, AxiosError>
) => {
  const {publicApi} = useApi()
  return useQuery<ArrayBuffer, AxiosError>(
    [QueryKey, url, contentType, fileId?.toString()],
    async () => {
      const absUrl = `/documents/${url}`

      const response = await publicApi.get<ArrayBuffer>(absUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': contentType,
          Accept: contentType
        }
      })

      return response.data
    },
    {
      cacheTime: Number.MAX_SAFE_INTEGER,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
