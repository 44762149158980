import {Close, DeleteOutlined} from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import {LoadingButton} from '@mui/lab'
import {Box, Button, MenuItem, Stack, Typography} from '@mui/material'
import React, {FC, MouseEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {UseDeleteNotificationParams, useTranslationKey} from '../../../hooks'

type RemoveActionItemProps = {
  notificationId: string
  isRemoveMode: boolean
  deleteNotificationData: UseDeleteNotificationParams
  enableRemoveMode: () => void
  onCancel: () => void
}

export const RemoveActionItem: FC<RemoveActionItemProps> = ({
  notificationId,
  deleteNotificationData,
  onCancel,
  isRemoveMode,
  enableRemoveMode
}) => {
  const {t} = useTranslation()
  const {translations} = useTranslationKey()

  const {removeNotification, isLoading, error, clearState} = deleteNotificationData

  return (
    <MenuItem
      onClick={
        !isRemoveMode
          ? () => {
              enableRemoveMode()
            }
          : undefined
      }
      sx={[
        {py: 2},
        isRemoveMode && {
          '&:hover': {
            backgroundColor: 'white',
            cursor: 'default'
          }
        }
      ]}
      data-test-id="notification-actions-menu-item-delete"
    >
      {!isRemoveMode ? (
        <Typography
          variant="subtitle1"
          display="inline-flex"
          alignItems="center"
          gap={2}
          data-test-id="notification-actions-menu-item-label"
        >
          <DeleteOutlined
            sx={({palette}) => ({
              color: palette.error.light
            })}
          />
          {t(translations.action.delete)}
        </Typography>
      ) : (
        <Stack p={1}>
          <Typography variant="h3">{t(translations.action.delete)}?</Typography>
          <Typography mt={3}>{t(translations.label.shouldDelete)}</Typography>
          {error && (
            <Typography mt={1} color="error" data-test-id="remove-notification-error-label">
              {t(translations.label.error)}
            </Typography>
          )}
          <Box display="flex" mt={4} justifyContent="flex-end" gap={2}>
            <Button
              startIcon={<Close />}
              variant="text"
              disabled={isLoading}
              onClick={onCancel}
              data-test-id="remove-notification-cancel"
            >
              {t(translations.action.cancel)}
            </Button>
            <LoadingButton
              loading={isLoading}
              color="error"
              variant="contained"
              startIcon={<DeleteIcon />}
              onClick={() => {
                if (error) {
                  clearState()
                }
                removeNotification(notificationId).catch(console.error)
              }}
              data-test-id="remove-notification-confirm"
            >
              {t(translations.action.confirmDelete)}
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </MenuItem>
  )
}
