/* eslint-disable jsx-a11y/media-has-caption */
import CloseIcon from '@mui/icons-material/Close'
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  Typography,
  AlertProps,
  Alert as MuiAlert,
  Box
} from '@mui/material'
import {TFunction} from 'i18next'
// eslint-disable-next-line import/default
import QrScanner from 'qr-scanner'
import React, {useRef, useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {useMobileBreakPoint} from '../../hooks/useWidth'

export const Alert: React.FC<AlertProps> = (props) => {
  return (
    <MuiAlert
      elevation={6}
      sx={{
        margin: 1.5,
        maxWidth: '400px',
        whiteSpace: 'pre-wrap',
        width: '100%'
      }}
      variant="filled"
      {...props}
    />
  )
}

const featureCheck = (label: string, support: boolean, t: TFunction) => {
  return (
    <Alert severity={support ? 'success' : 'error'}>
      {label}
      {!support && ` - ${t('barcodeScanner.label.notSupported')}`}
    </Alert>
  )
}

const MAX_SCANS_PER_SEC = 5

type BarcodeScannerPopUp = {
  open: boolean
  onClose: () => void
  onScan: (equipmentCode: string) => void
}

let hasCamera = false
void QrScanner.hasCamera().then((camera) => (hasCamera = camera))

export const BarcodeScannerPopUp: React.FC<BarcodeScannerPopUp> = ({open, onClose, onScan}) => {
  const fullScreen = useMobileBreakPoint()
  const videoRef = useRef<HTMLVideoElement>(null)
  const {t} = useTranslation()
  const qrScanner = useRef<QrScanner>()

  const closeScanner = useCallback(() => {
    qrScanner.current?.stop()
    onClose()
  }, [onClose, qrScanner])

  const onEnter = useCallback(() => {
    if (videoRef.current) {
      qrScanner.current = new QrScanner(
        videoRef.current,
        (result: QrScanner.ScanResult) => {
          onScan(result.data)
          closeScanner()
        },
        {
          maxScansPerSecond: MAX_SCANS_PER_SEC,
          returnDetailedScanResult: true
        }
      )
      void qrScanner.current?.start()
    }
  }, [closeScanner, onScan])

  const onExit = () => {
    qrScanner.current?.destroy()
    qrScanner.current = undefined
  }

  // video specifics
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
  const hasCameraSupport = navigator?.mediaDevices?.getUserMedia !== undefined
  const isSecureContext = Boolean(window.isSecureContext)
  const allFeaturesSupported = isSecureContext && hasCameraSupport && hasCamera

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      data-test-id="barcode-scanner-pop-up"
      TransitionProps={{onEnter, onExit}}
    >
      {allFeaturesSupported && (
        <DialogActions
          sx={{
            position: 'absolute',
            zIndex: 3,
            width: '100%'
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <IconButton
              data-test-id={'barcode-scanner-close-btn'}
              onClick={closeScanner}
              sx={{backgroundColor: 'common.white'}}
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{color: 'primary.contrastText'}}>Scan the equipment code</Typography>
          </Grid>
        </DialogActions>
      )}
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 0
        }}
      >
        {!allFeaturesSupported && (
          <>
            {featureCheck(t('barcodeScanner.label.secureAppContext'), isSecureContext, t)}
            {featureCheck(t('barcodeScanner.label.cameraSupport'), hasCameraSupport, t)}
            {featureCheck(t('barcodeScanner.label.scannerSupport'), hasCamera, t)}
          </>
        )}
        {allFeaturesSupported && (
          <>
            <Box
              component={'video'}
              autoPlay
              ref={videoRef}
              width="100%"
              height={'100%'}
              sx={{
                objectFit: 'cover',
                position: 'absolute',
                zIndex: 1
              }}
            />
            <Box
              sx={{
                width: '75vw',
                height: '75vw',
                border: '5px white solid',
                zIndex: 2,
                borderRadius: 2,
                position: 'absolute',
                bottom: '50%',
                transform: 'translateY(50%)',
                boxShadow: '0 0 0 50vh rgb(0 0 0 / 60%)'
              }}
            />
          </>
        )}
        {!qrScanner.current && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 0
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      {!allFeaturesSupported && (
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Button
              data-test-id="barcode-scanner-pop-up-exit-button"
              onClick={closeScanner}
              color="primary"
              variant="contained"
            >
              {t('barcodeScanner.label.exit')}
            </Button>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
}
