import {ConsoleProcessor} from '../processors'

import type {LogLevelProcessor} from './Log'

export const defaultProcessor: LogLevelProcessor = {...ConsoleProcessor}

let processors: Set<LogLevelProcessor> = new Set([{...defaultProcessor}])
/**
 * Replaces the processors that are called for each log level. Takes in an array of processors.
 */

export const setProcessors = (newProcessors: LogLevelProcessor[]): void => {
  processors = new Set(newProcessors)
}
/**
 * Adds a new level processor to the existing set of level processors (which is by default is a single console processor).
 */
export const addProcessors = (newProcessors: LogLevelProcessor[]): void =>
  // eslint-disable-next-line @typescript-eslint/unbound-method
  newProcessors.forEach(processors.add, processors)

export const getProcessors = (): Set<LogLevelProcessor> => processors
