import type {Role} from '@hconnect/apiclient'
import type MockAdapter from 'axios-mock-adapter/types'

import type {MockStorage} from './MockStorage'

export class MockRoles {
  constructor(private storage: MockStorage) {}

  addRole(userId: string | {id: string}, role: Role) {
    const id = typeof userId === 'string' ? userId : userId.id
    const current = this.storage.load()
    current.roles = {
      ...current.roles,
      [id]: [...this.getRoles(id), role]
    }
    this.storage.save(current)
  }

  removeRole(userId: string | {id: string}, predicate: (r: Role) => boolean) {
    const id = typeof userId === 'string' ? userId : userId.id
    const current = this.storage.load()
    current.roles = {
      ...current.roles,
      [id]: this.getRoles(id).filter((r) => !predicate(r))
    }
    this.storage.save(current)
  }

  getRoles(userId: string | {id: string}): Role[] {
    const id = typeof userId === 'string' ? userId : userId.id
    const current = this.storage.load()
    return current.roles[id] || []
  }

  removeAllRoles(userId: string | {id: string}) {
    const id = typeof userId === 'string' ? userId : userId.id
    const current = this.storage.load()
    delete current.roles[id]
    this.storage.save(current)
  }

  registerDfResponseForAxios(mockAdapter: MockAdapter) {
    mockAdapter.onGet(/\/roles\?userid=.+/i).reply((config) => {
      if (!config.url) return [500, undefined]
      const userId = config.url.split('=')[1]
      const roles = this.getRoles(userId)
      return [200, roles]
    })
  }
}
