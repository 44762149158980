import {ChecklistStatus, ChecklistTemplateKind} from '../types'

export const HROC_CHECKLIST_KIND_TRANSLATION_KEYS: Record<ChecklistTemplateKind, string> = {
  take5: 'checklist.templateKind.take5'
}

export const HROC_CHECKLIST_STATUS_TRANSLATION_KEYS: Record<ChecklistStatus, string> = {
  pending: 'checklist.status.pending',
  done: 'checklist.status.done'
}
