import {InputLabel, Select, MenuItem, FormControl, Box} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PlantSelectOption} from '../../hproduce/types'
import {getTranslationKey} from '../../utils/translation.utils'

type Props = {
  plants: PlantSelectOption[]
  translationPrefix?: string
}

export const PlantSelectDropdown: React.FC<Props> = ({plants, translationPrefix}) => {
  const {t} = useTranslation()
  const sortedPlants = useMemo(
    () => plants?.sort((a, b) => (a.plantName > b.plantName ? 1 : -1)),
    [plants]
  )

  return (
    <FormControl
      variant="standard"
      sx={{
        width: '100%',
        background: 'rgba(0, 0, 0, 0.04)',
        border: '1.5px solid rgba(255, 255, 255, 0.4)',
        boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
        borderRadius: 0.5,
        '& .MuiInputBase-formControl': {
          margin: '7px'
        },
        '& .MuiInputBase-input': {
          paddingY: '7px'
        },
        '& .MuiInput-underline, & .MuiInput-underline:hover': {
          '&::after,&::before': {
            borderBottom: 'none !important'
          }
        }
      }}
      data-test-id="select-plant"
    >
      <InputLabel
        shrink={false}
        sx={(theme) => ({
          color: theme.palette.grey['400'],
          '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.grey['400']
          },
          '&.MuiInputLabel-formControl': {
            top: -7,
            left: 7
          }
        })}
        data-test-id="select-plant-label"
      >
        {t(getTranslationKey('plantSelection.selectPlant', translationPrefix))}
      </InputLabel>
      <Select>
        {sortedPlants?.map((detail: PlantSelectOption) => (
          <Box
            component={'a'}
            key={detail.plantId}
            href={detail.url}
            sx={(theme) => ({
              color: theme.palette.grey['900'],
              textDecoration: 'none'
            })}
            data-test-id={`plant-select-${detail.plantId}`}
          >
            <MenuItem
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'transparent !important'
                }
              }}
            >
              {detail.plantName}
            </MenuItem>
          </Box>
        ))}
      </Select>
    </FormControl>
  )
}
