import React from 'react'
import {useTranslation} from 'react-i18next'

import {STOPPAGE_TYPE_TRANSLATION_KEYS} from '../../consts'
import {StoppageType} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {CommonDropDown, CommonProps} from './SharedDropDowns'

type StoppageTypeItem = {
  type: StoppageType
  description: string
  hidden?: boolean
}

export const EventStoppageTypeDropDown: React.FC<CommonProps<StoppageType>> = (props) => {
  const {t} = useTranslation()
  const stoppageTypes: StoppageTypeItem[] = [
    {
      type: 'kiln',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['kiln'], props.translationPrefix)
      )
    },
    {
      type: 'cementMill',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['cementMill'], props.translationPrefix)
      )
    },
    {
      type: 'rawMill',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['rawMill'], props.translationPrefix)
      )
    },
    {
      type: 'coalMill',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['coalMill'], props.translationPrefix)
      )
    },
    {
      type: 'crusher',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['crusher'], props.translationPrefix)
      )
    },
    {
      type: 'alternativeFuelInstallation',
      description: t(
        getTranslationKey(
          STOPPAGE_TYPE_TRANSLATION_KEYS['alternativeFuelInstallation'],
          props.translationPrefix
        )
      )
    },
    {
      type: 'bypass',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['bypass'], props.translationPrefix)
      )
    },
    {
      type: 'other',
      description: t(
        getTranslationKey(STOPPAGE_TYPE_TRANSLATION_KEYS['other'], props.translationPrefix)
      )
    }
  ]

  return (
    <CommonDropDown
      {...props}
      keyName="stoppageType"
      options={stoppageTypes.map((st) => st.type)}
      customTemplate={(type) => {
        const {description} = stoppageTypes.find((st) => st.type === type) ?? {description: ''}
        return description
      }}
    />
  )
}
