import {AttachmentFile, Attachment} from '../types'

export function getUrlsForRemovedImages(attachments: Attachment[], images: AttachmentFile[]) {
  // find images that have been removed
  const attachmentsToRemove: string[] = []
  attachments.forEach((at) => {
    const isStillThere = images.find((i) => i.id === at.id) !== undefined
    if (!isStillThere) attachmentsToRemove.push(at.url)
  })

  return attachmentsToRemove
}

export function getNewlyAttachedFiles(attachments: Attachment[], allImages: AttachmentFile[]) {
  return allImages.filter(({id}) => {
    const isPresent = attachments.find(({id: originalImageId}) => id === originalImageId)
    return !isPresent
  })
}
