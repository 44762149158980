import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {useQuery, UseQueryOptions} from 'react-query'

import {EquipmentPlain} from '../types'

import {useApi} from './useApi'

const QueryKey = 'search-by-main-equipment'

const ITEMS_PER_RESPONSE = 100

export const useEquipmentByMainEquipment = (
  plantId: string,
  searchPattern: string | undefined,
  mainEquipmentId?: string,
  options?: UseQueryOptions<EquipmentPlain[], AxiosError>
) => {
  const {publicApi} = useApi()
  const {
    i18n: {language}
  } = useTranslation()

  return useQuery<EquipmentPlain[], AxiosError>(
    [QueryKey, mainEquipmentId, searchPattern, language, plantId],
    async () => {
      const path = `/plantconfig/${plantId}/equipmentsById/${mainEquipmentId}`
      const params = {
        params: {
          language,
          ...(searchPattern && {searchPattern}),
          itemsPerResponse: ITEMS_PER_RESPONSE
        }
      }

      const response = await publicApi.get<EquipmentPlain[]>(path, params)
      return response.data
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  )
}
