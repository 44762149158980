export const getPlantIdFromDataScope = (dataScope: string): string | null => {
  try {
    const parsedScope = dataScope ? JSON.parse(dataScope) : null
    if (parsedScope === null) return null
    if (!('plantId' in parsedScope)) return null

    const plantId = parsedScope.plantId
    if (typeof plantId !== 'string') return null

    return plantId
  } catch (e) {
    return null
  }
}

export const plantIdCheckSucceed = (dataScope: string, activePlantId: string) => {
  // there are permission types that don't require a plantId
  if (!dataScope.includes('plantId')) return true

  // but if a plant id is present in the dataScope, it has to match the active PlantId
  const plantId = getPlantIdFromDataScope(dataScope)
  return plantId && activePlantId === plantId
}
