import axios, {AxiosError} from 'axios'
import queryString from 'query-string'
import {useCallback, useEffect, useRef, useState} from 'react'

import {useApi} from '../../hooks/useApi'
import {
  COUNT_SEGMENT,
  MY_UNREAD_IN_APP_NOTIFICATIONS_SEGMENT,
  NOTIFICATIONS_SEGMENT,
  REFETCH_INTERVAL_IN_MS,
  MAX_QUERY_RETRIES
} from '../consts'
import {RequestDataResult} from '../types'

export const useCountUnreadNotifications = (
  channels: string[],
  onSuccess: (count: number, prevCount: number | null) => void
): RequestDataResult<number | null> => {
  const {publicApi} = useApi()
  const [data, setData] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<AxiosError | null>(null)
  const errorCount = useRef<number>(0)

  const handleFetchCount = useCallback(async () => {
    try {
      const response = await publicApi.get<number>(
        `/${NOTIFICATIONS_SEGMENT}/${MY_UNREAD_IN_APP_NOTIFICATIONS_SEGMENT}/${COUNT_SEGMENT}?${queryString.stringify(
          {channels}
        )}`
      )
      setData((prevData) => {
        onSuccess(response.data, prevData)
        setError(null)
        return response.data
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error)
        errorCount.current = errorCount.current + 1
      } else {
        console.error(error)
      }
    }
    setIsLoading(false)
  }, [channels, publicApi, onSuccess])

  const refetch = useCallback(async () => {
    await handleFetchCount()
  }, [handleFetchCount])

  useEffect(() => {
    const interval = setInterval(() => {
      if (errorCount.current <= MAX_QUERY_RETRIES) {
        void handleFetchCount()
      }
    }, REFETCH_INTERVAL_IN_MS)
    void handleFetchCount()

    return () => clearInterval(interval)
  }, [handleFetchCount])

  useEffect(() => {
    const interval = setInterval(() => {
      // clear errors count
      errorCount.current = 0
    }, REFETCH_INTERVAL_IN_MS * 10)
    return () => clearInterval(interval)
  }, [])

  return {data, isLoading, error, refetch}
}
