import {LoadingSwitcher} from '@hconnect/uikit/src/lib2'
import {Add, Cancel} from '@mui/icons-material'
import {Box, Divider, IconButton, SxProps, Theme} from '@mui/material'
import {isEmpty} from 'lodash'
import {nanoid} from 'nanoid'
import React, {useCallback, useRef} from 'react'

import {imageBoxSx, clickableSx} from '../../styles'
import {AttachmentFile} from '../../types'

import {AttachmentHandler, imageFluidSx} from './AttachmentHandler'

export enum FileUploadEventType {
  FILE_TYPE_INVALID = 'FILE_TYPE_INVALID',
  FILE_SIZE_TOO_LARGE = 'FILE_SIZE_TOO_LARGE',
  FILE_DUPLICATED = 'FILE_DUPLICATED',
  FILE_LIMIT_REACHED = 'FILE_LIMIT_REACHED',
  FILE_REMOVED = 'FILE_REMOVED'
}

type FileTypeInvalid = {type: FileUploadEventType.FILE_TYPE_INVALID}
type FileSizeTooLarge = {type: FileUploadEventType.FILE_SIZE_TOO_LARGE}
type FileDuplicated = {type: FileUploadEventType.FILE_DUPLICATED}
type FileLimitReached = {type: FileUploadEventType.FILE_LIMIT_REACHED}
type FileRemoved = {type: FileUploadEventType.FILE_REMOVED; undoRemove: () => void}

export type FileUploadEvent = (
  | FileTypeInvalid
  | FileSizeTooLarge
  | FileDuplicated
  | FileLimitReached
  | FileRemoved
) & {fileName: string}

type DeletedFiles = Record<string, AttachmentFile>

type DocumentFormat = '.pdf' | '.doc' | '.docx'
type PresentationFormat = '.ppt' | '.pptx'
type ImageFormat = '.png' | '.jpg' | '.jpeg'
type WorksheetFormat = '.xlsx' | '.xls'
type VideoFormat = '.mp4' | '.3gp' | '.mov'

export type AllowedFileFormat =
  | DocumentFormat
  | PresentationFormat
  | ImageFormat
  | WorksheetFormat
  | VideoFormat

export interface AttachementUploadConfig {
  allowedFormats: AllowedFileFormat[]
  maxAttachements: number
  maxFileSizeMB: number
  isAddingEnabled?: boolean
  isDeletingEnabled?: boolean
}

export const AttachmentUpload: React.FC<{
  attachments: AttachmentFile[]
  setAttachments: (attachments: AttachmentFile[]) => void
  uploadEventsCallback?: (event: FileUploadEvent) => void
  config: AttachementUploadConfig
  attachmentsTitle?: JSX.Element
  containerSx?: SxProps<Theme>
}> = ({
  attachments,
  setAttachments,
  uploadEventsCallback,
  config,
  attachmentsTitle,
  containerSx
}) => {
  const deletedFilesForUndo = useRef<DeletedFiles>({})
  const loading = !Array.isArray(attachments)

  const {
    allowedFormats,
    maxAttachements,
    maxFileSizeMB,
    isAddingEnabled = false,
    isDeletingEnabled = false
  } = config

  const onFileChange = useCallback(
    (newAttachments: FileList) => {
      if (attachments.length <= maxAttachements) {
        const attachmentsToAppend: AttachmentFile[] = []
        Array.from(newAttachments).forEach((attachment) => {
          if (!allowedFormats.some((format) => attachment.name.toLowerCase().includes(format))) {
            return uploadEventsCallback?.({
              type: FileUploadEventType.FILE_TYPE_INVALID,
              fileName: attachment.name
            })
          }
          // converting MB to binary Bytes
          if (attachment.size > maxFileSizeMB * 1024 * 1024) {
            return uploadEventsCallback?.({
              type: FileUploadEventType.FILE_SIZE_TOO_LARGE,
              fileName: attachment.name
            })
          }
          const attachmentToAppend: AttachmentFile = {
            name: attachment.name,
            contentType: attachment.type,
            preview: URL.createObjectURL(attachment),
            isImage: attachment.type.includes('image'),
            originalFile: attachment
          }

          attachmentsToAppend.push(attachmentToAppend)
        })

        const duplicateFiles = attachmentsToAppend.filter((a) =>
          attachments.some((b) => b.name === a.name)
        )

        const fileCountExceededFiles = attachmentsToAppend
          .filter((a) => !duplicateFiles.includes(a))
          .slice(maxAttachements - attachments.length)

        if (duplicateFiles.length > 0) {
          duplicateFiles.forEach((filtered) => {
            uploadEventsCallback?.({
              type: FileUploadEventType.FILE_DUPLICATED,
              fileName: filtered.name
            })
          })
        }

        if (fileCountExceededFiles.length > 0) {
          fileCountExceededFiles.forEach((exceeded) => {
            uploadEventsCallback?.({
              type: FileUploadEventType.FILE_LIMIT_REACHED,
              fileName: exceeded.name
            })
          })
        }

        const merge = attachments.concat(
          attachmentsToAppend
            .filter((a) => !attachments.some((b) => b.name === a.name))
            .slice(0, maxAttachements - attachments.length)
        )

        setAttachments(merge)
      }
    },
    [
      attachments,
      setAttachments,
      allowedFormats,
      maxAttachements,
      maxFileSizeMB,
      uploadEventsCallback
    ]
  )

  const undoRemoveAttachment = useCallback(
    (key: string, prevAttachments: AttachmentFile[]) => {
      const fileToRecover = deletedFilesForUndo.current[key]
      if (!fileToRecover) return
      const isMaxAttachmentsExceeded = prevAttachments.length >= maxAttachements
      setAttachments(
        isMaxAttachmentsExceeded ? prevAttachments : [...prevAttachments, fileToRecover]
      )
    },
    [setAttachments, maxAttachements]
  )

  const removeAttachment = useCallback(
    (index: number) => {
      const key = nanoid()
      const copy = [...attachments]
      const [deletedFile] = copy.splice(index, 1)
      setAttachments(copy)
      deletedFilesForUndo.current[key] = deletedFile
      const undoRemove = () => {
        undoRemoveAttachment(key, copy)
      }
      uploadEventsCallback?.({
        type: FileUploadEventType.FILE_REMOVED,
        fileName: deletedFile.name,
        undoRemove
      })
    },
    [attachments, setAttachments, undoRemoveAttachment, uploadEventsCallback]
  )

  return (
    <LoadingSwitcher isLoading={loading}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...(containerSx ?? {})
          }}
        >
          {attachmentsTitle}
          {attachments.length < maxAttachements && isAddingEnabled && (
            <>
              <IconButton data-test-id="add-attachment-button">
                <Box
                  component="label"
                  display="flex"
                  htmlFor="attachmentInput"
                  data-test-id="add-image-placeholder"
                >
                  <Add sx={{color: 'primary.main', cursor: 'pointer'}} />
                </Box>
              </IconButton>
              <Box
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    onFileChange(e.target.files)
                    e.target.value = ''
                  }
                }}
                component="input"
                type="file"
                multiple={true}
                accept={allowedFormats.join(', ')}
                id="attachmentInput"
                display="none"
              />
            </>
          )}
        </Box>
        {!isEmpty(attachments) && <Divider sx={{borderColor: 'common.black'}} />}
        <Box component="span" sx={imageBoxSx()}>
          {attachments.map((file, index) => (
            <span key={index} data-test-id={`upload-file-section-img-${index}`}>
              {isDeletingEnabled && (
                <Box
                  component={'span'}
                  sx={(theme) => ({
                    position: 'relative',
                    top: -10,
                    right: 10,
                    float: 'right',
                    width: 0,
                    height: 0,
                    '& svg': {
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '12px'
                    },
                    ...clickableSx()
                  })}
                >
                  <Cancel
                    data-test-id={`delete-image-${index}`}
                    onClick={() => removeAttachment(index)}
                  />
                </Box>
              )}
              <AttachmentHandler
                key={file.id}
                file={file.preview}
                alt={file.name}
                iconOrPreview={file.isImage ? 'preview' : 'icon'}
                mediaType={file.contentType}
                sx={imageFluidSx}
              />
            </span>
          ))}
        </Box>
      </div>
    </LoadingSwitcher>
  )
}
