import {
  CheckCircle as CheckCircleFilled,
  CheckCircleOutline,
  Warning as WarningFilled,
  WarningAmber as WarningOutline,
  Error as ErrorFilled,
  ErrorOutline
} from '@mui/icons-material'

import {KpiRangeBreachSeverity} from '../../types'

const KpiThresholdOutlinedIcons = {
  [KpiRangeBreachSeverity.Alarm]: ErrorOutline,
  [KpiRangeBreachSeverity.Warning]: WarningOutline,
  [KpiRangeBreachSeverity.Optimal]: CheckCircleOutline
} as const

const KpiThresholdFilledIcons = {
  [KpiRangeBreachSeverity.Alarm]: ErrorFilled,
  [KpiRangeBreachSeverity.Warning]: WarningFilled,
  [KpiRangeBreachSeverity.Optimal]: CheckCircleFilled
} as const

export const KpiThreadholdColors = {
  [KpiRangeBreachSeverity.Alarm]: 'error',
  [KpiRangeBreachSeverity.Warning]: 'warning',
  [KpiRangeBreachSeverity.Optimal]: 'success'
} as const

export const KpiThresholdIcon = ({
  status,
  dataTestId,
  variant = 'outlined'
}: {
  status?: KpiRangeBreachSeverity
  dataTestId?: string
  variant?: 'outlined' | 'filled'
}) => {
  if (!status) return null

  const Icon =
    variant === 'outlined' ? KpiThresholdOutlinedIcons[status] : KpiThresholdFilledIcons[status]
  const color = KpiThreadholdColors[status]

  return <Icon fontSize="small" color={color} data-test-id={dataTestId} />
}
