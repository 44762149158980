import {mergeSxs} from '@hconnect/uikit/src/lib2'
import {Box, SxProps, Theme, Typography} from '@mui/material'
import React from 'react'

export const LegendItem = ({
  color,
  label,
  square,
  sx,
  'data-test-id': dataTestId
}: {
  color: string
  label: string
  square?: boolean
  sx?: SxProps<Theme>
  'data-test-id'?: string
}) => (
  <Typography
    display="flex"
    variant="caption"
    alignItems="center"
    color="textPrimary"
    data-test-id={dataTestId}
  >
    <Box
      component="span"
      mr={0.5}
      sx={mergeSxs(
        {
          backgroundColor: color,
          verticalAlign: 'text-bottom',
          width: 12,
          height: 12,
          borderRadius: square ? 0 : '50%'
        },
        sx
      )}
    />
    {label}
  </Typography>
)
