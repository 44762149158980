import {useNotification} from '@hconnect/uikit'
import {ShellIconButton} from '@hconnect/uikit/src/lib2'
import {NotificationsNoneOutlined} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Badge,
  Box,
  IconButton,
  Popover,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from '@mui/material'
import {debounce, isEmpty} from 'lodash'
import React, {SyntheticEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {BADGE_MAX} from '../../consts'
import {useTranslationKey} from '../../hooks'
import {NotificationsConfig, Notification} from '../../types'
import {groupNotifications} from '../../utils'
import {NotificationDropdownContent} from '../notification-dropdown-content'
import {NotificationPopup} from '../notification-popup'

type NotificationDropdownProps = {
  config: NotificationsConfig
  timezone?: string
}

export const NotificationDropdown: React.FC<NotificationDropdownProps> = ({config, timezone}) => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const {translations} = useTranslationKey()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>()

  const {
    notificationsRequestData,
    onNotificationClick,
    clearNewNotifications,
    newNotificationsDetails,
    unreadNotificationsCount
  } = config
  const {data, isLoading, error, hasNextPage, fetchNextPage, refetch} = notificationsRequestData

  const groupedNotifications = useMemo(() => groupNotifications(data), [data])

  useEffect(() => {
    if (isEmpty(newNotificationsDetails)) return

    newNotificationsDetails.forEach((notification: Notification) => {
      notify(
        'default',
        <NotificationPopup
          notification={notification}
          timezone={timezone}
          onClick={async () => await onNotificationClick(notification)}
          data-test-id="notification-dropdown-snackbar-content"
        />
      )
    })

    clearNewNotifications()
  }, [newNotificationsDetails, clearNewNotifications, notify, data, timezone, onNotificationClick])

  const handleClick = useCallback(
    (event: SyntheticEvent<EventTarget>) => setAnchorEl(event.currentTarget as HTMLInputElement),
    []
  )
  const handleClose = useCallback(() => setAnchorEl(null), [])

  const onScroll = useCallback(
    ({target}) => {
      if (
        hasNextPage &&
        Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight
      ) {
        void fetchNextPage()
      }
    },
    [fetchNextPage, hasNextPage]
  )

  const trackScrolling = debounce(onScroll, 200)

  return (
    <Box>
      <ShellIconButton
        onClick={handleClick}
        data-test-id="notifications-icon"
        id="notifications-icon"
        sx={(theme) => ({
          ...(anchorEl ? {backgroundColor: theme.palette.grey[200], boxShadow: 'none'} : {})
        })}
      >
        <Badge
          badgeContent={config.unreadNotificationsCount}
          color="primary"
          max={BADGE_MAX}
          sx={{
            '& .MuiBadge-badge': {
              px: 0.5,
              fontSize: 10,
              fontWeight: 700,
              height: 16,
              minWidth: 16,
              right: -4,
              top: -4
            }
          }}
          data-test-id="notifications-icon-badge"
        >
          <NotificationsNoneOutlined sx={{fontSize: 16}} />
        </Badge>
      </ShellIconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        open={!!anchorEl}
        onClose={handleClose}
        sx={(theme) => ({
          '& .MuiPopover-paper': {
            mt: 2.5,
            width: !isMobile ? 400 : `calc(100% - ${theme.spacing(4)})`,
            minWidth: 340,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: `calc(100% - ${theme.spacing(10)})`,
            minHeight: 260
          }
        })}
        data-test-id="notifications-popover"
      >
        <Stack
          sx={{overflowY: 'auto', flex: 1}}
          onScroll={hasNextPage ? trackScrolling : undefined}
          data-test-id="notification-dropdown-container"
        >
          <Box p={2} display="flex" justifyContent="space-between">
            <Typography
              variant="h3"
              color="common.black"
              display="inline-flex"
              alignItems="center"
              gap={1}
            >
              {t(translations.label.notifications)}
              {!!unreadNotificationsCount && (
                <Box
                  sx={{
                    display: 'inline-flex',
                    backgroundColor: 'primary.main',
                    borderRadius: 1.25,
                    minWidth: 20,
                    px: 0.75,
                    height: 20,
                    justifyContent: 'center',
                    color: 'common.white',
                    fontSize: 12,
                    alignItems: 'center',
                    fontWeight: 700
                  }}
                  data-test-id="notifications-dropdown-badge"
                >
                  {unreadNotificationsCount < BADGE_MAX
                    ? unreadNotificationsCount
                    : `${BADGE_MAX}+`}
                </Box>
              )}
            </Typography>
            <IconButton
              onClick={handleClose}
              color="primary"
              data-test-id="notification-dropdown-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <NotificationDropdownContent
            error={error}
            isLoading={isLoading}
            groupedNotifications={groupedNotifications}
            onNotificationClick={onNotificationClick}
            timezone={timezone}
            hasNextPage={hasNextPage}
            refetchNotifications={refetch}
          />
        </Stack>
      </Popover>
    </Box>
  )
}
