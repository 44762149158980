import {GridSetting} from '@hconnect/uikit'

import {TableSize} from '../components/eventsList/types'
import {BreakPoint} from '../hooks/useWidth'

export const SIDE_CARD_MIN_HEIGHT_IN_PX = '450px'

export const FIX_COLUMN_WIDTH = '160px'
export const FIX_COLUMN_WIDTH_XS = '50px'

export const withSideCard = new Map<BreakPoint, TableSize>([
  // on xs and sm will the table be replaced by the side card
  ['md', 'S'],
  ['lg', 'M'],
  ['xl', 'L']
])

export const noSideCard = new Map<BreakPoint, TableSize>([
  ['xs', 'S'],
  ['sm', 'M'],
  ['md', 'L'],
  ['lg', 'XL'],
  ['xl', 'XL']
])

export const sideCardSize: GridSetting = {
  xs: 12,
  md: 6,
  lg: 5,
  xl: 4
}

export const tableWithSideCard: GridSetting = {
  xs: 12,
  md: 6,
  lg: 7,
  xl: 8
}

export const tableWithOutSideCard: GridSetting = {
  xs: 12
}

export const TEXT_SEARCH_HIGHLIGHT_COLOR = '#fdd835'
export const TEXT_SEARCH_HIGHLIGHT_FOCUS_COLOR = '#fe943f'
