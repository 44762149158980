import {AxiosInstance, AxiosRequestConfig} from 'axios'

import {Permission} from '../response'
import {StringParams} from '../types'

export const getPermissionsByUserId =
  (api: AxiosInstance) =>
  async (
    userId: string,
    params: StringParams = {},
    axiosConfig?: AxiosRequestConfig
  ): Promise<Permission[]> => {
    const config = {params: {...params, userId}, ...axiosConfig}
    const response = await api.get<Permission[]>('/permissions', config)
    return response.data
  }
