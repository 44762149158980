import {Column} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {isUndefined} from 'lodash'
import React from 'react'

import {ManualKpiToUpdate, ManualKpi} from '../types'

import {formatNumber} from './numberFormat.helpers'
import {getTranslationKey} from './translation.utils'

export const generateKpi = (data: Partial<ManualKpiToUpdate>): ManualKpiToUpdate => ({
  name: data.name ?? '',
  unit: data.unit ?? '',
  processStage: data.processStage ?? ''
})

export const generateKpisToUpdate = (data: ManualKpi[]): ManualKpiToUpdate[] =>
  data.map((kpi) => ({
    name: kpi.name,
    unit: kpi.unit,
    processStage: kpi.processStage,
    id: kpi.id,
    concurrencyStamp: kpi.concurrencyStamp,
    actualValue: kpi.actualValue,
    plannedValue: kpi.plannedValue,
    delta: kpi.delta
  }))

export const COLUMN_SX = {
  px: {xs: 0.75, sm: 0.75}
}

export const generateColumns = (
  t: TFunction,
  language: string,
  translationPrefix?: string
): Column<ManualKpiToUpdate>[] => [
  {
    key: 'name',
    label: t(getTranslationKey('performance.label.kpi', translationPrefix)),
    columnSx: COLUMN_SX,
    customTemplate: ({name}) => <Typography variant="h5">{name}</Typography>
  },
  {
    key: 'planned',
    label: t(getTranslationKey('performance.label.planned', translationPrefix)),
    width: 105,
    columnSx: COLUMN_SX,
    customTemplate: ({plannedValue, unit}) =>
      isUndefined(plannedValue) ? (
        <></>
      ) : (
        <Typography variant="body1">
          {formatNumber(plannedValue, language)} {unit}
        </Typography>
      )
  },
  {
    key: 'actual',
    label: t(getTranslationKey('performance.label.actual', translationPrefix)),
    width: 105,
    columnSx: COLUMN_SX,
    customTemplate: ({actualValue, unit}) =>
      isUndefined(actualValue) ? (
        <></>
      ) : (
        <Typography variant="body1">
          {formatNumber(actualValue, language)} {unit}
        </Typography>
      )
  }
]
