import {isEmpty, omitBy} from 'lodash'

import {LoggerMetadata, LogLevelProcessor, Severity} from '../../lib'

const getTagsExtras = ({tags, extras}: LoggerMetadata): Record<string, unknown>[] => {
  const additionalMeta = omitBy({tags, extras}, isEmpty)
  return isEmpty(additionalMeta) ? [] : [additionalMeta]
}

/**
 * Simple log processor that sends events to the console log.
 * @param project
 * @constructor
 */
export const ConsoleProcessor: LogLevelProcessor = {
  [Severity.Debug]: (level, event, metadata) => console.debug(event, ...getTagsExtras(metadata)),
  [Severity.Info]: (level, event, metadata) => console.info(event, ...getTagsExtras(metadata)),
  [Severity.Log]: (level, event, metadata) => console.log(event, ...getTagsExtras(metadata)),
  [Severity.Warning]: (level, event, metadata) => console.warn(event, ...getTagsExtras(metadata)),
  [Severity.Error]: (level, event, metadata) => console.error(event, ...getTagsExtras(metadata)),
  [Severity.Critical]: (level, event, metadata) => console.error(event, ...getTagsExtras(metadata)),
  [Severity.Fatal]: (level, event, metadata) => console.error(event, ...getTagsExtras(metadata))
}
