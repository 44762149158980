import {MiddlewareFn} from '../../lib'

export const messageFormatMiddleware: MiddlewareFn = (event, metadata, level) => {
  const {project, context} = metadata
  const path = [project, ...(context ?? [])].join(' / ')
  const separator = ' :: '
  const formattedString = [
    new Date().toISOString(),
    level.toUpperCase(),
    `[${path}]`,
    event.toString()
  ].join(separator)
  return [formattedString, metadata]
}
