import {Box, Theme, SxProps, Tooltip} from '@mui/material'
import React from 'react'

export const IconWithLabel: React.FC<{
  icon: React.ReactElement
  label: string
  iconOnly?: boolean
  sx?: SxProps<Theme>
  'data-test-id'?: string
}> = (props) => {
  // do not remove the <i /> inside the tooltip as every tooltip needs a valid element child,
  // or we will get an error
  if (props.iconOnly === true) {
    return (
      <Box
        component="span"
        display="flex"
        alignItems="center"
        alignContent="center"
        sx={props.sx}
        data-test-id={props['data-test-id']}
      >
        <Tooltip title={props.label} placement="top">
          {props.icon ?? <i />}
        </Tooltip>
      </Box>
    )
  } else {
    return (
      <Box
        component="span"
        display="flex"
        alignItems="center"
        overflow="hidden"
        sx={{
          '& :first-of-type': {
            mr: 1
          },
          ...(props.sx || {})
        }}
        data-test-id={props['data-test-id']}
      >
        {props.icon} {props.label}
      </Box>
    )
  }
}
