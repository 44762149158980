import {useCallback} from 'react'

import {useApi} from '../../hooks/useApi'
import {MY_READ_IN_APP_NOTIFICATIONS_SEGMENT, NOTIFICATIONS_SEGMENT} from '../consts'

export const useMarkNotificationsAsRead = (onSuccess?: (id: string) => void) => {
  const {publicApi} = useApi()

  return useCallback(
    async (id: string) => {
      try {
        await publicApi.post<string[]>(
          `/${NOTIFICATIONS_SEGMENT}/${MY_READ_IN_APP_NOTIFICATIONS_SEGMENT}`,
          [id]
        )
        onSuccess?.(id)
      } catch (error) {
        console.error(error)
      }
    },
    [onSuccess, publicApi]
  )
}
