import {PercentPieChart} from '@hconnect/uikit/src/lib2'
import {Box, Tooltip, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentExpertSystemUsage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {ContentBox} from '../ContentBox'

export const ExpertSystemEquipmentCharts: React.FC<{
  expertSystemEquipments: EquipmentExpertSystemUsage[]
  translationPrefix?: string
  isLoading?: boolean
}> = ({expertSystemEquipments, translationPrefix, isLoading}) => {
  const {t} = useTranslation()
  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={t(getTranslationKey('expertSystemUtilization.title', translationPrefix))}
      bodyWithPadding
      data-test-id="expert-sys-util"
      isLoading={isLoading}
    >
      <Box display="flex" sx={{flexWrap: 'wrap'}}>
        {expertSystemEquipments.map(
          ({
            expertSystemOnPercentage,
            expertSystemEquipmentShortName,
            equipmentNumber,
            processStage,
            subPlant
          }) => (
            <Tooltip
              title={
                <Box data-test-id="es-tooltip">
                  {t(
                    getTranslationKey(
                      `expertSystemUtilization.processStage.${processStage}`,
                      translationPrefix
                    )
                  )}
                  <Typography>{subPlant}</Typography>
                </Box>
              }
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -20]
                      }
                    }
                  ]
                }
              }}
              key={`${equipmentNumber}-${processStage}-tooltip`}
            >
              <span>
                <PercentPieChart
                  percent={expertSystemOnPercentage}
                  text={expertSystemEquipmentShortName}
                  key={`${equipmentNumber}-${processStage}`}
                  data-test-id={`expert-system-eq-${equipmentNumber}-${processStage}`}
                />
              </span>
            </Tooltip>
          )
        )}
      </Box>
    </ContentBox>
  )
}
