import {Typography} from '@mui/material'
import {Moment} from 'moment-timezone'
import React, {FC, memo} from 'react'
import {useTranslation} from 'react-i18next'

import {StatusWithCancellation} from '../../types'
import {timezonedDateValidator} from '../../utils'

const statusesNotEligibleForOverdue: StatusWithCancellation[] = ['done', 'cancelled']

export const OverdueLabel: FC<{
  dueDate: Moment
  status: StatusWithCancellation
  plantNow: Moment
  translationFunc?: (key: string) => string
}> = memo(({dueDate, status, plantNow, translationFunc}) => {
  const {t} = useTranslation()

  timezonedDateValidator(dueDate, plantNow)

  const translationFunction = translationFunc || t

  const markDueDateBefore = plantNow.clone().startOf('day')

  return !statusesNotEligibleForOverdue.includes(status) &&
    markDueDateBefore &&
    dueDate.isBefore(markDueDateBefore) ? (
    <Typography
      variant="caption"
      color="error"
      sx={{
        verticalAlign: 1
      }}
    >
      {translationFunction('summary.overdue')}
    </Typography>
  ) : (
    <></>
  )
})
