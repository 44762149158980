import {SeverityLevel} from '@microsoft/applicationinsights-web'

import {LogLevelProcessor, ProcessorFn, Severity} from '../../lib'

import {AppInsightsAdapter} from './AppInsightsAdapter'

const mapSeverityToAzure: Record<Severity, SeverityLevel> = {
  [Severity.Debug]: SeverityLevel.Verbose,
  [Severity.Info]: SeverityLevel.Information,
  [Severity.Log]: SeverityLevel.Information,
  [Severity.Warning]: SeverityLevel.Warning,
  [Severity.Error]: SeverityLevel.Error,
  [Severity.Critical]: SeverityLevel.Critical,
  [Severity.Fatal]: SeverityLevel.Critical
}

const sendException: ProcessorFn = (level, event, metadata) => {
  const exception: Error = event instanceof Error ? event : new Error(event)
  const severityLevel = mapSeverityToAzure[level]
  AppInsightsAdapter.trackException({
    exception,
    severityLevel,
    properties: {...metadata, level}
  })
}
const sendEvent: ProcessorFn = (level, event, metadata) => {
  const name = event instanceof Error ? event.message : event
  AppInsightsAdapter.trackEvent(name, {...metadata, event, level})
}
const sendTrace: ProcessorFn = (level, event, metadata) => {
  const severityLevel = mapSeverityToAzure[level]
  const message = event instanceof Error ? event.message : event
  AppInsightsAdapter.trackTrace({message, severityLevel, properties: {...metadata, level}}, {})
}

export const AzureProcessor: LogLevelProcessor = {
  [Severity.Debug]: sendTrace,
  [Severity.Info]: sendTrace,
  [Severity.Log]: sendTrace,
  [Severity.Warning]: sendEvent,
  [Severity.Error]: sendException,
  [Severity.Critical]: sendException,
  [Severity.Fatal]: sendException
}
