import {IconWithLabelButton, IconWithTooltipButton} from '@hconnect/uikit/src/lib2'
import {buttonCheckedSx, buttonSx} from '@hconnect/uikit/src/lib2/components/button/styles'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import React from 'react'

import {eventActionButtonSX} from '../../consts'

type EventHighlightProps = {
  value?: boolean
  onChange: (value?: boolean) => void
  title: string
  withLabel?: boolean
  'data-test-id'?: string
  disabled?: boolean
}

export const EventHighlight: React.FC<EventHighlightProps> = ({
  disabled,
  value,
  onChange,
  title,
  withLabel,
  'data-test-id': dataTestId = 'highlight-event'
}) => {
  const onClick = () => {
    if (value) {
      onChange(undefined)
    } else {
      onChange(true)
    }
  }

  return withLabel ? (
    <IconWithLabelButton
      startIcon={<StarBorderIcon />}
      onClick={onClick}
      data-test-id={dataTestId}
      disabled={disabled}
      label={title}
      variant="outlined"
      sx={(theme) => ({
        ...(value ? buttonCheckedSx(theme) : buttonSx(theme)),
        ...eventActionButtonSX
      })}
    />
  ) : (
    <IconWithTooltipButton
      title={title}
      icon={<StarBorderIcon />}
      onClick={onClick}
      data-test-id={dataTestId}
      checked={value}
      disabled={disabled}
    />
  )
}
