import {get} from 'lodash'
import moment from 'moment'

export type Validator<T> = (value, item: Partial<T>) => string | undefined
export type ValidatorConfig<T> = Map<string, Validator<T>>

export const isISO8601DatetimeString = (d: string): boolean =>
  moment(
    d,
    [
      'YYYY-MM-DD[T]HH:mm:ss[Z]',
      'YYYY-MM-DD[T]HH:mm:ss.S[Z]',
      'YYYY-MM-DD[T]HH:mm:ss.SS[Z]',
      'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]',
      'YYYY-MM-DD[T]HH:mm:ssZ',
      'YYYY-MM-DD[T]HH:mm:ss.SZ',
      'YYYY-MM-DD[T]HH:mm:ss.SSZ',
      'YYYY-MM-DD[T]HH:mm:ss.SSSZ'
    ],
    true
  ).isValid()

export const isString = (d): boolean => typeof d === 'string'

export const isInteger = (d): boolean => Number.isInteger(d)

export const isGitSha1 = (d): boolean => {
  const re = new RegExp('[0-9a-fA-F]{40}')
  return isString(d) && !!d.match(re)
}

export const shouldNotBeEmpty =
  <V>(): Validator<V> =>
  <T>(value: T) => {
    return value ? undefined : 'shouldBe.notEmpty'
  }

export const shouldNotBeEmptyString =
  <T>(): Validator<T> =>
  (value: string) => {
    return value && value.length > 0 ? undefined : 'shouldBe.notEmpty'
  }

export const shouldNotBeEmptyStringWithMaxLength =
  <T>(maxLength: number, textTransformFunction?: (string) => string): Validator<T> =>
  (value: string) => {
    const text = textTransformFunction ? textTransformFunction(value) : value
    if (text?.length > maxLength) {
      return 'shouldBe.shorter'
    }
    return shouldNotBeEmptyString<T>()(text, {})
  }

export const validateAll = <T>(config: ValidatorConfig<T>, item: Partial<T>) => {
  const validationError = new Map()

  config.forEach((validator, key) => {
    const failed = validator(get(item, key), item)

    if (failed) {
      validationError.set(key, failed)
    }
  })

  return validationError
}
