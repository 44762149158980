import {Autocomplete, Paper, TextField} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX} from '../../consts'
import {useDebouncedString} from '../../hooks/useDebouncedString'
import {useMainEquipmentNumberSearchQuery} from '../../hooks/useMainEquipmentNumberSearchQuery'
import {EquipmentData, EquipmentPlain} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'
import {getEquipmentLabel} from '../shiftEventLabels'

import {EquipmentHierarchy} from './Equipment'

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />

const emptyArray = []

function getDisplayPath(equipment: EquipmentData) {
  const equipmentForDisplay: EquipmentPlain = {equipment: equipment}

  return (
    equipment && (
      <span data-test-id={`main-equipment-search-suggestion-${equipment.id}`}>
        <EquipmentHierarchy equipmentPlain={equipmentForDisplay} withId={false} />
      </span>
    )
  )
}

export function areMainEquipmentsSame(option1: EquipmentData, option2: EquipmentData) {
  return option1 && option2 && option1.id === option2.id
}

type Props = {
  plantId: string
  label?: React.ReactNode
  errorText?: React.ReactNode
  onChange: (equipment: EquipmentData | undefined) => void
  value: EquipmentData | undefined
  required?: boolean
  disabled?: boolean
  translationPrefix?: string
}
export const MainEquipmentSearchHierarchy = (props: Props) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState<boolean>(false)

  const [inputValue, setInputValue] = useState<string>('')
  const debouncedQuery = useDebouncedString(inputValue, 1000)
  const searchQuery = useMainEquipmentNumberSearchQuery(debouncedQuery, props.plantId)

  const options = searchQuery.data && searchQuery.data.length > 0 ? searchQuery.data : emptyArray

  const value = props.value?.text

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value, setInputValue])

  return (
    <Autocomplete
      data-test-id="main-equipment-search"
      value={props.value ?? null}
      inputValue={inputValue}
      open={open && options.length > 0}
      onOpen={() => {
        setOpen(true)
      }}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          props.onChange(undefined)
          return
        }
        if (next && typeof next !== 'string') {
          props.onChange(next)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input' && newValue !== inputValue) {
          setInputValue(newValue)

          if (!newValue && props.value) {
            props.onChange(undefined)
          }
        }
      }}
      options={options}
      getOptionLabel={(plain) => getEquipmentLabel(plain)}
      isOptionEqualToValue={areMainEquipmentsSame}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!props.errorText}
            data-test-id="main-equipment-search-input"
            helperText={props.errorText}
            label={
              t(getTranslationKey('shiftEvent.label.mainEquipment', props.translationPrefix)) +
              (!props.required
                ? ` (${t(getTranslationKey('shiftEvent.label.optional', props.translationPrefix))})`
                : '')
            }
            required={props.required}
            placeholder={t(getTranslationKey('pleaseStartTyping', props.translationPrefix))}
            variant="filled"
            InputProps={{...params.InputProps, ...INPUT_FORM_FIELD_SX}}
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
      renderOption={(props, option) => <li {...props}>{getDisplayPath(option)}</li>}
      // per default the auto-compleat component will use the getOptionLabel,
      // compare it with the given input text and will filter out all that do not match.
      // that is a problem when having sapNumbers were we remove the leading zeros
      // 00000008321003 will be rendered as 8321003 and therefor not match if the input is 0083
      // and will not be presented as an option
      // to avoid this behavior, we override the filterOptions function to force it to compare on object level
      filterOptions={(options) => options}
      disabled={props.disabled}
    />
  )
}
