import {Stack, Typography} from '@mui/material'
import React from 'react'

import {KpiRangeBreachSeverity} from '../../types'
import {formatNumber} from '../../utils'

import {KpiThresholdIcon} from './KpiThresholdIcon'

type Props = {
  value: number
  language?: string
  unit?: string
  showKpiStatusIndicator?: boolean
  kpiBreachSeverity: KpiRangeBreachSeverity | null
  dataTestId?: string
}
export const KpiValue: React.FC<Props> = ({
  value,
  language,
  unit,
  showKpiStatusIndicator,
  kpiBreachSeverity,
  dataTestId
}) => {
  return (
    <>
      <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
        <Typography variant="body1">
          {!isNaN(value) ? `${formatNumber(value, language ?? '')} ${unit ?? ''}` : '-'}
        </Typography>
        {showKpiStatusIndicator && !!kpiBreachSeverity && (
          <KpiThresholdIcon status={kpiBreachSeverity} dataTestId={dataTestId} />
        )}
      </Stack>
    </>
  )
}
