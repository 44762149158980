import {Box, Tooltip} from '@mui/material'
import {isEmpty} from 'lodash'
import React from 'react'

import {EquipmentData} from '../../types'
import {getHighlightedTitle} from '../../utils/highlightHelpers'

export const getEquipmentLabelDetails = (equipment: EquipmentData | undefined, withId = true) =>
  equipment && !isEmpty(equipment) ? (
    <EquipmentLabel withId={withId} withText equipment={equipment} />
  ) : (
    '-'
  )

export const getEquipmentLabel = (equipment: EquipmentData, withoutId?: boolean) => {
  const id = getEquipmentIdLabel(equipment)
  return withoutId
    ? equipment.text
      ? equipment.text
      : ''
    : `${id}${equipment.text ? ` - ${equipment.text}` : ''}`
}

export const getEquipmentIdLabel = (equipment: EquipmentData) => {
  // remove the leading zeros from the SAP number
  if (equipment.idType === 'sapNumber') {
    return equipment.id.replace(/^0+/, '')
  }
  return equipment.id
}

export const EquipmentLabel: React.FC<{
  equipment: EquipmentData
  withText?: boolean
  withId?: boolean
  withTooltip?: boolean
  withEllipsis?: boolean
  fullWidth?: boolean
  freeSearchText?: string
}> = ({
  equipment,
  withId = true,
  withText = false,
  withTooltip = false,
  withEllipsis = false,
  fullWidth = false,
  freeSearchText
}) => {
  let result: string
  if (withId && withText) {
    result = getEquipmentLabel(equipment)
  } else if (withId) {
    result = getEquipmentIdLabel(equipment)
  } else {
    result = equipment.text || ''
  }

  if (withTooltip && withEllipsis) {
    return (
      <Tooltip title={result} placement="top">
        <Box
          component="span"
          sx={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'block',
            ...(!fullWidth && {maxWidth: 160})
          }}
        >
          {freeSearchText ? getHighlightedTitle(result, freeSearchText) : result}
        </Box>
      </Tooltip>
    )
  } else if (withTooltip && !withEllipsis) {
    return (
      <Tooltip title={result} placement="top">
        <span>{result}</span>
      </Tooltip>
    )
  } else {
    return (
      <span>
        {result ? (freeSearchText ? getHighlightedTitle(result, freeSearchText) : result) : '-'}
      </span>
    )
  }
}
