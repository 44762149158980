import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton} from '@mui/material'
import React, {FC} from 'react'

import {Notification} from '../../types'
import {NotificationCard} from '../notification-card'

type NotificationPopupProps = {
  notification: Notification
  onClick: (notification: Notification) => Promise<void>
  timezone?: string
  'data-test-id'?: string
  'card-data-test-id'?: string
}

export const NotificationPopup: FC<NotificationPopupProps> = ({
  notification,
  onClick,
  timezone,
  'data-test-id': dataTestId,
  'card-data-test-id': cardDataTestId
}) => (
  <Box
    sx={{backgroundColor: 'common.white', borderRadius: 1, minWidth: 300}}
    data-test-id={dataTestId}
  >
    <NotificationCard
      notification={notification}
      timezone={timezone}
      onClick={() => onClick(notification)}
      actions={
        <IconButton onClick={() => void onClick(notification)} color="primary" sx={{minWidth: 38}}>
          <CloseIcon />
        </IconButton>
      }
      data-test-id={cardDataTestId}
    />
  </Box>
)
