import queryString from 'query-string'
import {generatePath} from 'react-router'

import {apps} from '../hproduce'
import {EnvUrls} from '../hproduce/types'
import {EventAppInfo} from '../types'

export const APP_EVENT_ROUTE_MAP: Record<'Cockpit' | 'easyPOM', string> = {
  Cockpit: '/:plantId/events',
  easyPOM: '/:plantId/actions'
}
export const APP_EVENT_NAME_MAP: Record<'Cockpit' | 'easyPOM', string> = {
  Cockpit: 'eventId',
  easyPOM: 'actionId'
}

export const getDomain = (appUrls: EnvUrls) =>
  getHost(appUrls).replace(/(https?:\/\/[^/]+).*/, '$1')

export const getEventRoute = ({app, plantId, externalId}: EventAppInfo) => {
  const appUrls = apps[app]?.url
  if (appUrls && APP_EVENT_NAME_MAP[app]) {
    const domain = getDomain(appUrls)
    return `${generatePath(domain + APP_EVENT_ROUTE_MAP[app], {
      plantId
    })}?${queryString.stringify({[APP_EVENT_NAME_MAP[app]]: externalId})}`
  }
  return '#'
}

export const getHost = (hosts: {[key: string]: string}) => {
  const stage = process.env.REACT_APP_STAGE
  const stageSpecificHost = (stage && hosts[stage]) || ''
  return stageSpecificHost
}
