import {useCallback, useEffect, useState} from 'react'

import {Notification, NotificationsConfig, NotificationsOptions} from '../types'

import {useCountUnreadNotifications} from './useCountUnreadNotifications'
import {useMarkNotificationsAsRead} from './useMarkNotificationsAsRead'
import {useNotifications} from './useNotifications'

export const useAppNotifications = (options: NotificationsOptions): NotificationsConfig => {
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>()
  const [newNotificationsDetails, setNewNotificationsDetails] = useState<Notification[]>([])

  const onNotificationSuccess = useCallback((notifications: Notification[]) => {
    setNewNotificationsCount((current) => {
      if (current) {
        setNewNotificationsDetails(notifications.slice(0, current))
        return 0
      }
    })
  }, [])
  const notificationsRequestData = useNotifications(options, onNotificationSuccess)

  const fetchNotifications = useCallback(
    (id?: string) => {
      notificationsRequestData.refetch(true, id)
    },
    [notificationsRequestData]
  )
  const markAsReadAction = useMarkNotificationsAsRead(fetchNotifications)

  const onCountSuccess = useCallback((count: number, prevCount: number | null) => {
    if (prevCount !== null && count > prevCount) {
      setNewNotificationsCount(count - prevCount)
    }
  }, [])
  const {data: unreadNotificationsCount, refetch: refetchUnreadNotificationsCount} =
    useCountUnreadNotifications(options.channels, onCountSuccess)

  useEffect(() => {
    if (newNotificationsCount) {
      void fetchNotifications()
    }
  }, [fetchNotifications, newNotificationsCount])

  const clearNewNotifications = useCallback(() => setNewNotificationsDetails([]), [])

  const onNotificationClick = useCallback(
    async (notification: Notification) => {
      if (!notification.read) {
        await markAsReadAction(notification.id)
        await refetchUnreadNotificationsCount()
      }
    },
    [markAsReadAction, refetchUnreadNotificationsCount]
  )

  return {
    newNotificationsDetails,
    notificationsRequestData,
    unreadNotificationsCount,
    clearNewNotifications,
    onNotificationClick
  }
}
