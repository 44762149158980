import {DateFormat} from '@hconnect/uikit'
import React from 'react'

import {TimeFormat} from './TimeFormat'

import {DateTime} from '@hconnect/common/types'

type DateTimeFormatProps = {
  date?: DateTime | null
  separator?: string
  timezone: string
}

export const DateTimeFormat: React.FC<DateTimeFormatProps> = ({date, separator, timezone}) => {
  if (!date) {
    return <>{'-'}</>
  }

  return (
    <>
      <DateFormat date={date} timezone={timezone} />
      {separator || ' - '}
      <TimeFormat date={date} timezone={timezone} />
    </>
  )
}
