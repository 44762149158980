import {GaugeBar} from '@hconnect/uikit/src/lib2'
import {Divider, Grid, Stack, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {DifficultySummaries, QuestionDifficulty} from '../../types'
import {completionScoreBarColor, DIFFICULTY_LABEL_KEY, formatCompletion} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'

import {RatingTooltip} from './RatingTooltip'

interface CompletionSectionProps {
  difficultySummaries: DifficultySummaries
  activeDifficulty: QuestionDifficulty
  translationPrefix?: string
  onDifficultySectionClick?: (difficulty: QuestionDifficulty) => void
  'data-test-id'?: string
}

type BarSectionProps = {
  difficultySummaries: DifficultySummaries
  questionDifficulty: QuestionDifficulty
  activeDifficulty: QuestionDifficulty
  t: TFunction
  translationPrefix?: string
}
const BarSection: FC<BarSectionProps> = ({
  t,
  activeDifficulty,
  questionDifficulty,
  difficultySummaries,
  translationPrefix
}) => {
  const isActiveDifficulty = activeDifficulty === questionDifficulty
  const color = isActiveDifficulty ? 'primary.light' : 'secondary.main'

  return (
    <Stack width="fit-content">
      <Typography variant="h5" color={color}>
        {t(getTranslationKey(DIFFICULTY_LABEL_KEY[questionDifficulty], translationPrefix))}
      </Typography>
      <Typography variant="caption" fontWeight={600} color={color}>
        {difficultySummaries[questionDifficulty].answeredPositively}/
        {difficultySummaries[questionDifficulty].questionCount}
      </Typography>
      {isActiveDifficulty && <Divider sx={{mt: 0.5, borderWidth: 1, borderColor: color}} />}
    </Stack>
  )
}

export const CompletionSection: React.FC<CompletionSectionProps> = ({
  difficultySummaries,
  onDifficultySectionClick,
  activeDifficulty,
  translationPrefix,
  'data-test-id': dataTestId
}) => {
  const {t} = useTranslation()

  return (
    <Grid container spacing={0.25} data-test-id={dataTestId}>
      <Grid item xs={5} sm={6}>
        <RatingTooltip
          translationPrefix={translationPrefix}
          value={difficultySummaries.basic.positivePercent}
          summaries={difficultySummaries}
        >
          <GaugeBar
            value={difficultySummaries.basic.answeredPositively}
            maxValue={difficultySummaries.basic.questionCount}
            color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Basic)}
            minLabelWidth={56}
            barHeight={40}
            onClick={
              onDifficultySectionClick && (() => onDifficultySectionClick(QuestionDifficulty.Basic))
            }
            customSubtitle={
              <BarSection
                translationPrefix={translationPrefix}
                activeDifficulty={activeDifficulty}
                difficultySummaries={difficultySummaries}
                questionDifficulty={QuestionDifficulty.Basic}
                t={t}
              />
            }
            data-test-id="rating-basic"
          />
        </RatingTooltip>
      </Grid>
      <Grid item xs={4}>
        <GaugeBar
          value={difficultySummaries.advanced.answeredPositively}
          maxValue={difficultySummaries.advanced.questionCount}
          color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Advanced)}
          format={formatCompletion}
          minLabelWidth={56}
          barHeight={40}
          onClick={
            onDifficultySectionClick &&
            (() => onDifficultySectionClick(QuestionDifficulty.Advanced))
          }
          customSubtitle={
            <BarSection
              translationPrefix={translationPrefix}
              activeDifficulty={activeDifficulty}
              difficultySummaries={difficultySummaries}
              questionDifficulty={QuestionDifficulty.Advanced}
              t={t}
            />
          }
          data-test-id="rating-advanced"
        />
      </Grid>
      <Grid item xs={3} sm={2}>
        <GaugeBar
          value={difficultySummaries.excellence.answeredPositively}
          maxValue={difficultySummaries.excellence.questionCount}
          color={completionScoreBarColor(difficultySummaries, QuestionDifficulty.Excellence)}
          format={formatCompletion}
          minLabelWidth={56}
          barHeight={40}
          onClick={
            onDifficultySectionClick &&
            (() => onDifficultySectionClick(QuestionDifficulty.Excellence))
          }
          customSubtitle={
            <BarSection
              translationPrefix={translationPrefix}
              activeDifficulty={activeDifficulty}
              difficultySummaries={difficultySummaries}
              questionDifficulty={QuestionDifficulty.Excellence}
              t={t}
            />
          }
          data-test-id="rating-excellence"
        />
      </Grid>
    </Grid>
  )
}
