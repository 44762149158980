import {AppName} from '../../hproduce'

export const REFETCH_INTERVAL_IN_MS = 60000
export const MAX_QUERY_RETRIES = 3
export const DEFAULT_ITEMS_PER_PAGE = 10
export const BADGE_MAX = 999

export const SERVER_TO_PRODUCT_APP_NAME_MAP: Record<string, AppName> = {
  cockpit: 'Cockpit',
  easypom: 'easyPOM',
  maintain: 'Maintain'
}
