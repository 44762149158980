import moment, {Moment} from 'moment-timezone'

/**
 * Returns the hours and minutes of a time string
 * @param time 'HH:mm:ss'
 * @returns 'HH:mm'
 */
export const getHoursMinutes = (time?: string) => {
  if (!time) return ''
  const [hours, minutes] = time.split(':')
  return `${hours}:${minutes}`
}

function convertDateToUtc(date: Moment, fromTimezone: string): Moment {
  const timezoned = moment.tz(date, fromTimezone)
  const offset = timezoned.utcOffset()
  return timezoned.clone().add(offset, 'minutes').utc()
}
function convertDateUtcToLocalTime(date: Moment, toTimezone: string) {
  const timezoned = moment.tz(date, toTimezone)
  const offset = timezoned.utcOffset()
  return timezoned.clone().subtract(offset, 'minutes').utc()
}
export function fromBrowsertimeToPlantTime(date: Moment, fromTimezone: string, toTimezone: string) {
  return convertDateUtcToLocalTime(convertDateToUtc(date, fromTimezone), toTimezone)
}

export const timezonedDateValidator = (a?: Moment | null, b?: Moment | null) => {
  const timezone = a?.tz()
  if (b && timezone && b.tz() !== timezone) {
    throw new Error(
      `Provided date is not in the plant timezone. Expected: ${timezone}, received: ${b.tz()}`
    )
  }
}
