import {Grid, Popover, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {DifficultySummaries} from '../../types'
import {completionScoreBandColor, QUESTION_DIFFICULTIES} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'

type RatingTooltipProps = {
  children: React.ReactNode
  value: number
  summaries: DifficultySummaries
  translationPrefix?: string
  hideTotal?: boolean
}

export const RatingTooltip: React.FC<RatingTooltipProps> = ({
  children,
  value,
  summaries,
  translationPrefix,
  hideTotal
}) => {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const totalPositive = useMemo(
    () =>
      QUESTION_DIFFICULTIES.reduce((acc, difficulty) => {
        acc += summaries[difficulty].answeredPositively
        return acc
      }, 0),
    [summaries]
  )

  const totalQuestions = useMemo(
    () =>
      QUESTION_DIFFICULTIES.reduce((acc, difficulty) => {
        acc += summaries[difficulty].questionCount
        return acc
      }, 0),
    [summaries]
  )

  const open = Boolean(anchorEl)

  return (
    <Typography
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      component="div"
    >
      {children}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'common.black',
            p: 2
          }
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        data-test-id="rating-popover"
      >
        <Grid container sx={{color: 'common.white'}} maxWidth={300}>
          {!hideTotal && (
            <Grid container spacing={3}>
              <Grid item xs={4} sx={{fontWeight: 600}}>
                {t(getTranslationKey('total', translationPrefix))}
              </Grid>

              <Grid item xs={4}>
                {totalPositive}/{totalQuestions}
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  color: completionScoreBandColor(value)
                }}
              >
                {value}%
              </Grid>
            </Grid>
          )}

          {summaries &&
            QUESTION_DIFFICULTIES.map((questionDifficulty) => {
              return (
                <Grid container key={questionDifficulty} spacing={3}>
                  <Grid item xs={4} data-test-id={`${questionDifficulty}-rating-difficulty`}>
                    {t(
                      getTranslationKey(
                        `questionDifficulty.${questionDifficulty}`,
                        translationPrefix
                      )
                    )}
                  </Grid>

                  <Grid item xs={4} data-test-id={`${questionDifficulty}-rating-division`}>
                    {summaries[questionDifficulty].answeredPositively}/
                    {summaries[questionDifficulty].questionCount}
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    sx={{
                      color: completionScoreBandColor(summaries[questionDifficulty].positivePercent)
                    }}
                    data-test-id={`${questionDifficulty}-rating-percent`}
                  >
                    {summaries[questionDifficulty].positivePercent}%
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Popover>
    </Typography>
  )
}
