import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material'
import React, {ReactNode, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {EquipmentData, RunningTime} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

import {CommonProps} from './SharedDropDowns'

export type RunningTimeDropDownItem = {
  id: string
  label: ReactNode
  equipment: EquipmentData
  runningTime: RunningTime
}

export type RunningTimesData<T> = {
  data: T[]
  fetchMore: () => void
  isLoading: boolean
}

type StoppageTimeDropDownProps = CommonProps<string> & {
  runningTimesData: RunningTimesData<RunningTimeDropDownItem>
  onChange: (newValue: string) => void
  translationPrefix?: string
}

const LOAD_MORE_ID = 'loadMore'

export const StoppageTimeDropDown: React.FC<StoppageTimeDropDownProps> = ({
  runningTimesData,
  translationPrefix,
  ...props
}) => {
  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const preventCloseRef = useRef(false)

  const {data: runningTimes, fetchMore, isLoading} = runningTimesData

  return (
    <FormControl variant="filled" fullWidth={true} required={true}>
      <InputLabel id="stoppage-time-drop-down" error={!!props.errorText}>
        {t(getTranslationKey('shiftEvent.label.selectStoppage', translationPrefix))}
      </InputLabel>
      <Select
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          if (preventCloseRef.current) {
            preventCloseRef.current = false
          } else {
            setIsOpen(false)
          }
        }}
        data-test-id="stoppage-time-drop-down"
        labelId="stoppage-time-drop-down"
        value={props.value || ''}
        onChange={(event: SelectChangeEvent<string>) => {
          if (event.target.value === LOAD_MORE_ID) {
            preventCloseRef.current = true
          } else {
            props.onChange(event.target.value)
          }
        }}
        renderValue={(selectedId) => {
          const selectedRunningTime = runningTimes.find(({id}) => id === selectedId)
          const equipmentLabel =
            selectedRunningTime?.equipment.text || selectedRunningTime?.equipment.id
          return (
            <>
              {equipmentLabel} - {selectedRunningTime?.label}
            </>
          )
        }}
      >
        {runningTimes.map((option) => (
          <MenuItem key={option.id} value={option.id} data-test-id={`stoppage-time-${option.id}`}>
            <ListItemText
              primary={
                <>
                  {option.equipment.text || option.equipment.id} {option.label}
                </>
              }
            />
          </MenuItem>
        ))}
        <MenuItem
          value={LOAD_MORE_ID}
          onClick={fetchMore}
          data-test-id="stoppage-time-drop-down-load-more"
          sx={{textAlign: 'center'}}
          disabled={isLoading}
        >
          <ListItemText
            primary={
              <Box
                component="span"
                sx={{
                  display: 'inline-flex',
                  position: 'relative',
                  alignItems: 'center',
                  textDecoration: 'underline'
                }}
              >
                {isLoading && (
                  <CircularProgress
                    size={12}
                    sx={({spacing}) => ({right: spacing(-2.5), position: 'absolute'})}
                  />
                )}
                {t(getTranslationKey('shiftEvent.loadMore', translationPrefix))}
              </Box>
            }
          />
        </MenuItem>
      </Select>
      {props.errorText && (
        <Typography variant="caption" color="error">
          {props.errorText}
        </Typography>
      )}
    </FormControl>
  )
}
