import {Block, Done, Remove, Loop, HourglassEmpty, Check, CancelOutlined} from '@mui/icons-material'
import {SvgIcon} from '@mui/material'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as SapIcon} from '../../assets/sap.svg'
import {StatusWithNoneAndCancellationAndApproverAndWorkOrderStates} from '../../types'
import {getTranslationKey} from '../../utils'
import {IconWithLabel} from '../shiftEventLabels'

export const EventStatusLabel: React.FC<{
  status: StatusWithNoneAndCancellationAndApproverAndWorkOrderStates
  iconOnly?: boolean
  sx?: SystemStyleObject
  t?: TFunction | ((key: string) => string)
  translationPrefix?: string
}> = (props) => {
  const {t} = useTranslation()
  const translate = props.t || t

  const icons: Record<StatusWithNoneAndCancellationAndApproverAndWorkOrderStates, React.ReactNode> =
    {
      pending: <HourglassEmpty color={'error'} />,
      ongoing: <Loop color={'secondary'} />,
      done: <Done color={'success'} />,
      cancelled: <Block color={'secondary'} />,
      none: <Remove />,
      approved: <Check color={'success'} />,
      rejected: <CancelOutlined color={'error'} />,
      waitingForApproval: <HourglassEmpty />,
      workOrderCreated: <SvgIcon component={SapIcon} />
    }

  return (
    <IconWithLabel
      sx={props.sx}
      icon={icons[props.status as string] ?? <Remove />}
      iconOnly={props.iconOnly}
      label={translate(
        getTranslationKey(`shiftEvent.status.${props.status}`, props.translationPrefix)
      )}
    />
  )
}
