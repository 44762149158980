import {LayoutConstants} from '@hconnect/uikit'
import {Box, Button, Typography, Link, Theme, SxProps} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

const styles: Record<string, SxProps<Theme>> = {
  container: (theme) => ({
    boxShadow: theme.shadows[4],
    background: theme.palette.common.white,
    zIndex: 1000000,
    width: '100%'
  }),
  root: {
    position: 'sticky'
  },
  progress: (theme) => ({
    verticalAlign: 'middle',
    marginRight: theme.spacing()
  }),
  colorPrimary: {
    color: 'red'
  },
  icon: {
    marginRight: 10
  }
}

type Props = {onClose: () => void}

export const PrivacyBanner: React.FC<Props> = ({onClose}) => {
  const {t} = useTranslation()

  return (
    <Box sx={styles.container}>
      <Box
        data-test-id="privacy-policy-change-banner"
        display="flex"
        justifyContent="space-between"
        width={LayoutConstants.DEFAULT_CONTENT_WIDTH}
        paddingLeft={2}
        paddingRight={2}
        paddingTop={1.5}
        m="auto"
        minHeight="48px"
        sx={styles.root}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
            <Box ml="auto" mr="auto">
              <Typography variant="body2" color="textPrimary" component="span">
                <Trans t={t} i18nKey="legalAcceptance.dataPrivacyPolicyDisclaimerUpdated">
                  The
                  <Link
                    color="primary"
                    role="link"
                    to="/privacy?noNav=true"
                    component={RouterLink}
                    target="__blank"
                  >
                    {t('legal.dataPrivacy')}
                  </Link>
                  {t('legal.policyUpdate')}
                </Trans>
              </Typography>
            </Box>
            <Button
              data-test-id="privacy-policy-change-banner-close-button"
              variant="text"
              color="primary"
              onClick={onClose}
            >
              {t('asyncjobqueue.close')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
