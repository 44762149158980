import {Moment} from 'moment-timezone'

import {RootCauseFailureAnalyses} from './analysis.types'
import {Iso8601} from './atomic.types'
import {StoppageKind} from './shifthandoverStoppages.types'
import {StoppageType} from './stoppage.types'

/** This is mainly used for stopped runningTimeType only.
 *  It is currently used in downtime dashboard.
 */
export type ThinRunningTimeDto = {
  id: number
  beginTechnical: Iso8601
  endTechnical: Iso8601
  durationInHours: number
}
export type ThinRunningTime = {
  id: number
  beginTechnical: Moment
  endTechnical: Moment
  durationInHours: number
}

export type RunningTimeStoppageDto = {
  stoppageKind: StoppageKind
  start: Iso8601
  end?: Iso8601
  stoppageType?: StoppageType
  cockpitStoppageId?: string
  stoppageCode?: string
  stoppageReason?: string
}
export type RunningTimeStoppage = {
  stoppageKind: StoppageKind
  start: Moment
  end?: Moment
  stoppageType?: StoppageType
  cockpitStoppageId?: string
  stoppageCode?: string
  stoppageReason?: string
}

export type RunningTimeDto = {
  id?: number
  beginTechnical?: Iso8601
  endTechnical?: Iso8601
  begin?: Iso8601
  end?: Iso8601
  runningTimeType?: RunningTimeType
  stoppageType?: StoppageType
  stoppageKind?: StoppageKind
  stoppageCode?: string
  stoppageReason?: string
  cockpitStoppageId?: string
  rootCauseFailureAnalyses?: RootCauseFailureAnalyses[]
  stoppages?: RunningTimeStoppageDto[]
  stoppageGroup?: string
}
export type RunningTime = {
  id?: number
  beginTechnical?: Moment
  endTechnical?: Moment
  begin?: Moment
  end?: Moment
  runningTimeType?: RunningTimeType
  stoppageType?: StoppageType
  stoppageKind?: StoppageKind
  rootCauseFailureAnalyses?: RootCauseFailureAnalyses[]
  stoppages?: RunningTimeStoppage[]
  stoppageGroup?: string
}

export enum RunningTimeType {
  RUNNING = 'running',
  STOPPED = 'stopped'
}

export enum OtherRunningTimeTypes {
  DEFAULT = 'default',
  UNKNOWN = 'unknown'
}

export type AllRunningTimeTypes = RunningTimeType | OtherRunningTimeTypes
