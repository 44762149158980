import React, {useMemo} from 'react'

import {useProcessStages} from '../../hooks/useProcessStages'
import {ProcessStageTitle} from '../shiftEventLabels'

import {CommonDropDown, CommonProps} from './SharedDropDowns'

type ProcessStageDropDownProps = CommonProps<string> & {
  plantId: string
}

export const EventProcessStageTitle: React.FC<{plantId: string; stage?: string}> = ({
  plantId,
  stage
}) => {
  const stages = useProcessStages(plantId).data

  return <ProcessStageTitle stages={stages} stage={stage} />
}

export const ProcessStageDropDown: React.FC<ProcessStageDropDownProps> = (props) => {
  const value = props.value
  const stages = useProcessStages(props.plantId).data

  const option = useMemo(() => {
    if (!stages) {
      return value ? [value] : []
    }
    if (value && !stages.has(value)) {
      return [value, ...stages.keys()]
    }
    return [...stages.keys()]
  }, [value, stages])

  return (
    <CommonDropDown
      {...props}
      disabled={option.length === 0 || props.disabled}
      keyName="processStage"
      options={option}
      customTemplate={(key) => {
        return <ProcessStageTitle stages={stages} stage={key} />
      }}
    />
  )
}
