import moment from 'moment-timezone'

import {ISO8601} from '../metrics/types'

export const shiftDate = (date: ISO8601, numberOfDays: number): ISO8601 => {
  return moment(date).add(numberOfDays, 'days').format('YYYY-MM-DD')
}

export const parseIsoDateStringToUtcTimestamp = (date: ISO8601): number => +moment.utc(date)

export const parseIsoDateStringToDate = (date: ISO8601): Date => moment.utc(date).toDate()
