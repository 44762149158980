import {TFunction} from 'i18next'
import {orderBy, isEmpty} from 'lodash'

import {STOPPAGE_TYPE_TRANSLATION_KEYS} from '@hconnect/common/consts'
import {StoppageType, StoppageKind, DowntimeStoppageCode} from '@hconnect/common/types'
import {toStoppageCodeSearchParam} from '@hconnect/common/utils/stoppageHelper'

export interface DropDownOption {
  id: string
  typeLabel: string
  label: string
  stoppageType: StoppageType
  stoppageCode: DowntimeStoppageCode
}

export interface DropDownOptionWithPriority extends DropDownOption {
  priority: number
}

type SortableDropDownOption = DropDownOption | DropDownOptionWithPriority

export const sortDropDownOptions = <T extends SortableDropDownOption>(options: T[]): T[] => {
  const hasPriority = options.some((option) => 'priority' in option)

  if (hasPriority) {
    return orderBy(options, ['priority', 'typeLabel', 'label'], ['desc', 'asc', 'asc'])
  } else {
    return orderBy(options, ['typeLabel', 'label'], ['asc', 'asc'])
  }
}

export const allOfTypeSelected = (
  allCodesMap: StoppageCodesMap<DowntimeStoppageCode>,
  selectedOptions: SortableDropDownOption[],
  stoppageType: StoppageType
): boolean => {
  const allCodes = allCodesMap[stoppageType] ?? []
  return (
    allCodes.length ===
    selectedOptions.filter((option) => option.stoppageType === stoppageType).length
  )
}

export const someOfTypeSelected = (
  selectedOptions: SortableDropDownOption[],
  stoppageType: StoppageType
): boolean => selectedOptions.some((option) => option.stoppageType === stoppageType)

export const codeToString = <T extends DowntimeStoppageCode>(stoppageCode: T): string => {
  return `${stoppageCode.code} ${stoppageCode.description}`
}

export const toggleAllFromType = <T extends SortableDropDownOption>(
  allDropDownOptions: T[],
  selectedOptions: T[],
  stoppageType: StoppageType
): T[] => {
  const optionsOfType = allDropDownOptions.filter((option) => option.stoppageType === stoppageType)
  const selectedOfType = selectedOptions.filter((option) => option.stoppageType === stoppageType)

  // this list should be untouched
  const selectedOfOtherType = selectedOptions.filter(
    (option) => option.stoppageType !== stoppageType
  )

  if (optionsOfType.length === selectedOfType.length) {
    return selectedOfOtherType
  }
  return [...selectedOfOtherType, ...optionsOfType]
}

export type StoppageCodesMap<T extends DowntimeStoppageCode> = Partial<
  Record<StoppageType, T[] | (T & {priority?: number})[]>
>

export const getOptionTypes = <T extends DowntimeStoppageCode>(
  options: StoppageCodesMap<T>
): StoppageType[] => Object.keys(options) as StoppageType[]

type MockFunction = (a: string) => string

export const stoppageCodesToDropDownOption = <T extends DowntimeStoppageCode>(
  stoppagesCodes: StoppageCodesMap<T>,
  stoppageKinds: StoppageKind[],
  t: TFunction | MockFunction
): SortableDropDownOption[] =>
  sortDropDownOptions(
    getOptionTypes(stoppagesCodes).flatMap((type) =>
      (stoppagesCodes[type] || [])
        .filter(({type}) => !stoppageKinds.length || stoppageKinds.includes(type))
        .map((stoppageCode) => ({
          id: toStoppageCodeSearchParam(type, stoppageCode.code),
          typeLabel: t(STOPPAGE_TYPE_TRANSLATION_KEYS[type]),
          label: codeToString(stoppageCode),
          stoppageCode,
          stoppageType: type,
          priority: 'priority' in stoppageCode ? stoppageCode.priority : undefined
        }))
    )
  )

export const toValueList = (selectedOptions: SortableDropDownOption[]): string[] | undefined => {
  const newSelection = selectedOptions.map((option) => option.id)
  return isEmpty(newSelection) ? undefined : newSelection
}
