import imageCompression from 'browser-image-compression'
import {toLower} from 'lodash'

// re-export from browser-image-compression
export interface CompressFileOptions {
  /** @default Number.POSITIVE_INFINITY */
  maxSizeMB?: number
  /** @default undefined */
  maxWidthOrHeight?: number
  /** @default true */
  useWebWorker?: boolean
  /** @default 10 */
  maxIteration?: number
  /** Default to be the exif orientation from the image file */
  exifOrientation?: number
  /** A function takes one progress argument (progress from 0 to 100) */
  onProgress?: (progress: number) => void
  /** Default to be the original mime type from the image file */
  fileType?: string
  /** @default 1.0 */
  initialQuality?: number
  /** @default false */
  alwaysKeepResolution?: boolean
  /** @default undefined */
  signal?: AbortSignal
}

export const compressFile = (file: File, options: CompressFileOptions = {}): Promise<File> => {
  if (toLower(file.type).startsWith('image/')) {
    return imageCompression(file, options)
  }
  return Promise.resolve(file)
}
