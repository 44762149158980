import {Column} from '@hconnect/uikit/src/lib2'
import React, {FC, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {ExpandedGroupIds} from '../../hooks/useExpandGroups'
import {ManualKpiToUpdate, ManualKpisProcessStage} from '../../types'
import {generateColumns} from '../../utils'

import {ManualKpiWrapper} from './ManualKpiWrapper'

type ManualKpiPreviewProps = {
  manualKpiList: ManualKpiToUpdate[]
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  processStages: ManualKpisProcessStage[]
  isProcessStagesLoading: boolean
  translationPrefix?: string
}

export const ManualKpiPreview: FC<ManualKpiPreviewProps> = ({
  manualKpiList,
  setOpenProcessStages,
  openProcessStages,
  processStages,
  isProcessStagesLoading,
  translationPrefix
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const columns = useMemo<Column<ManualKpiToUpdate>[]>(
    () => generateColumns(t, language, translationPrefix),
    [t, language, translationPrefix]
  )

  return (
    <ManualKpiWrapper
      setOpenProcessStages={setOpenProcessStages}
      openProcessStages={openProcessStages}
      isProcessStagesLoading={isProcessStagesLoading}
      processStages={processStages}
      manualKpiList={manualKpiList}
      columns={columns}
      translationPrefix={translationPrefix}
    />
  )
}
