import React, {useMemo} from 'react'

import {usePriorities} from '../../hooks/usePriorities'
import {Priority} from '../../types'

import {CommonDropDown, CommonProps} from './SharedDropDowns'

export const PriorityTitle: React.FC<{priority?: Priority}> = ({priority}) => {
  const priorities = usePriorities().data

  if (priority && priorities && priorities.has(priority)) {
    return <>{priorities.get(priority)}</>
  }

  return <>{priority?.toString(10) || ''}</>
}

export const PriorityDropDown: React.FC<CommonProps<Priority>> = ({disabled, ...props}) => {
  const value = props.value
  const priorities = usePriorities().data

  const option = useMemo(() => {
    if (!priorities) {
      return value ? [value] : []
    }
    if (value && !priorities.has(value)) {
      return [value, ...priorities.keys()]
    }
    return [...priorities.keys()]
  }, [value, priorities])

  return (
    <CommonDropDown
      {...props}
      disabled={disabled || option.length === 0}
      keyName="priority"
      options={option}
      customTemplate={(key) => {
        return <PriorityTitle priority={key} />
      }}
    />
  )
}
