import moment from 'moment-timezone'

import {RunningTime, RunningTimeDto} from '../types'

export const mapRunningTime = (runningTime: RunningTimeDto, timezone: string): RunningTime => ({
  ...runningTime,
  beginTechnical: runningTime.beginTechnical
    ? moment.utc(runningTime.beginTechnical).tz(timezone)
    : undefined,
  endTechnical: runningTime.endTechnical
    ? moment.utc(runningTime.endTechnical).tz(timezone)
    : undefined,
  begin: runningTime.begin ? moment.utc(runningTime.begin).tz(timezone) : undefined,
  end: runningTime.end ? moment.utc(runningTime.end).tz(timezone) : undefined,
  stoppages: runningTime.stoppages?.map((stoppage) => ({
    ...stoppage,
    start: moment.utc(stoppage.start).tz(timezone),
    end: stoppage.end ? moment.utc(stoppage.end).tz(timezone) : undefined
  }))
})

export const mapRunningTimeDto = (runningTime: RunningTime): RunningTimeDto => ({
  ...runningTime,
  beginTechnical: runningTime.beginTechnical ? runningTime.beginTechnical.toISOString() : undefined,
  endTechnical: runningTime.endTechnical ? runningTime.endTechnical.toISOString() : undefined,
  begin: runningTime.begin ? runningTime.begin.toISOString() : undefined,
  end: runningTime.end ? runningTime.end.toISOString() : undefined,
  stoppages: runningTime.stoppages?.map((stoppage) => ({
    ...stoppage,
    start: stoppage.start.toISOString(),
    end: stoppage.end ? stoppage.end.toISOString() : undefined
  }))
})

export const mapRunningTimes = (runningTimes: RunningTimeDto[], timezone: string): RunningTime[] =>
  runningTimes.map((runningTime) => mapRunningTime(runningTime, timezone))
