import {isUndefined} from 'lodash'
import {generatePath} from 'react-router'

import {PLANT_KMS_ROUTE} from '../consts'
import {MAINTAIN_HOST} from '../hproduce/config/maintain'

import {getHost} from './route.utils'

export const getMaintainPlantKmsUrl = (plantId: string, kilnId?: string) => {
  if (isUndefined(kilnId)) {
    return '#'
  }

  const stageSpecificHost = getHost(MAINTAIN_HOST)
  const path = generatePath(PLANT_KMS_ROUTE, {plantId, kilnId})

  return stageSpecificHost + path
}
