import IconWarning from '@mui/icons-material/Warning'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

type ErrorDialogProps = {
  onClose: () => void
  message?: string
  httpCode?: string
  statusCode?: number
  errorText?: string
  translationPrefix?: string
}

export const ErrorDialog: React.FC<ErrorDialogProps> = (props) => {
  const {t} = useTranslation()

  let errorText = props.errorText
  if (props.statusCode === 403) {
    errorText = t(getTranslationKey('error.noPermission', props.translationPrefix))
  }
  if (props.statusCode === 409) {
    errorText = t(getTranslationKey('error.conflictBody', props.translationPrefix))
  }
  const heading =
    props.statusCode === 409
      ? t(getTranslationKey('error.conflictHeading', props.translationPrefix))
      : t(getTranslationKey('shiftEvent.error.onDelete.heading', props.translationPrefix))

  return (
    <Dialog maxWidth="xs" data-test-id="error-on-delete-modal" onClose={props.onClose} open>
      <DialogTitle display="flex" alignItems="center">
        <IconWarning color={'error'} />
        &nbsp; {heading}
      </DialogTitle>
      <DialogContent>
        {props.message ||
          t(getTranslationKey('shiftEvent.error.onDelete.body', props.translationPrefix))}
        <br />
        {errorText}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
