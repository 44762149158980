import {Grid, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {PlantSelectOption} from '../../hproduce/types'
import {getTranslationKey} from '../../utils/translation.utils'

import SelectPlant from './assets/SelectPlant.svg'
import {PlantSelectDropdown} from './PlantSelectDropdown'

type Props = {
  plants: PlantSelectOption[]
  translationPrefix?: string
}

export const PlantSelect: React.FC<Props> = ({plants, translationPrefix}) => {
  const {t} = useTranslation()
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          src={SelectPlant}
          alt="Select-Plant-Img"
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              width: '373px'
            }
          })}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '335px'
          }}
        >
          <Box
            sx={{
              color: 'common.white',
              fontSize: '28px',
              fontWeight: 700,
              marginBottom: 2
            }}
            data-test-id="select-a-plant-label"
          >
            {t(getTranslationKey('plantSelection.selectAPlant', translationPrefix))}
          </Box>
          <Box
            sx={{
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: '16px',
              lineHeight: '22px',
              marginBottom: 4
            }}
            data-test-id="select-plant-description"
          >
            {t(getTranslationKey('plantSelection.desc', translationPrefix))}
          </Box>
          <PlantSelectDropdown plants={plants} translationPrefix={translationPrefix} />
        </Box>
      </Box>
    </Grid>
  )
}
