import {NotificationProvider} from '@hconnect/uikit' // import the snackbar provider
import {Box} from '@mui/material'
import {isEqual} from 'lodash'
import React, {memo} from 'react'

import {TranslationKeyProvider} from '../hooks'
import {useAppNotifications} from '../hooks/useAppNotifications'
import {NotificationsOptions, Translations} from '../types'

import {NotificationDropdown} from './notification-dropdown'

type NotificationsProps = {
  options: NotificationsOptions
  translations: Translations
  timezone?: string
}

export const Notifications = memo<NotificationsProps>(({options, translations, ...rest}) => {
  const config = useAppNotifications(options)

  return (
    <Box>
      <NotificationProvider
        key="notification-provider"
        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
        maxSnack={3}
        autoHideDuration={5000}
        sx={(theme) => ({
          padding: '0 !important',
          borderRadius: `${theme.spacing(1)} !important`,
          '.SnackbarItem-message': {
            padding: '0'
          }
        })}
      >
        <TranslationKeyProvider translations={translations}>
          <NotificationDropdown config={config} {...rest} />
        </TranslationKeyProvider>
      </NotificationProvider>
    </Box>
  )
}, isEqual)
