import {Box} from '@mui/material'
import React from 'react'

import {EquipmentPlain} from '../../types'
import {EquipmentLabel} from '../shiftEventLabels'

export const EquipmentHierarchy: React.FC<{
  equipmentPlain: EquipmentPlain
  separator?: React.ReactNode
  withId?: boolean
}> = ({equipmentPlain, separator, withId = true}) => {
  const filteredPaths = equipmentPlain?.path?.filter((item) => Object.keys(item).length) || []

  return (
    <span>
      {filteredPaths.map((e) => (
        <Box key={e.id} color="text.secondarySoft">
          <EquipmentLabel withId withText equipment={e} />
          {separator || <>&nbsp;&#8594;&nbsp;</>}
        </Box>
      ))}
      <EquipmentLabel withId={withId} withText equipment={equipmentPlain?.equipment} />
    </span>
  )
}
