export enum ChecklistTemplateType {
  HealthAndSafety = 'healthAndSafety',
  ProcessWorkOrders = 'processWorkOrders',
  POM = 'pom'
}

export enum ChecklistTemplateCategory {
  None = 'none',
  PreAssessment = 'preAssessment'
}

export enum ChecklistTemplateKind {
  TakeFive = 'take5'
}

export enum ChecklistStatus {
  PENDING = 'pending',
  DONE = 'done'
}

export type ChecklistTemplateDto = {
  id: string
  name: string
  description: string
  type: ChecklistTemplateType
  category: ChecklistTemplateCategory
  kind: ChecklistTemplateKind
  createdAt: string
}

export type TaskChecklist = {
  id: string
  kind: ChecklistTemplateKind
  status: ChecklistStatus
}

export type TaskChecklistsOption = Omit<TaskChecklist, 'status'>
