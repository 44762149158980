export enum StoppageKind {
  Incident = 'incident',
  Circumstance = 'circumstance',
  PlannedStoppage = 'plannedStoppage',
  Other = 'other'
}

export const shifthandoverStoppagesList: StoppageKind[] = [
  StoppageKind.Incident,
  StoppageKind.Circumstance,
  StoppageKind.PlannedStoppage
]

export const defaultDowntimeStoppages: StoppageKind[] = [
  StoppageKind.Incident,
  StoppageKind.Circumstance
]
export const allDowntimeStoppages: StoppageKind[] = [
  ...shifthandoverStoppagesList,
  StoppageKind.Other
]
