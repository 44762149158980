import {Box, Grid, Switch} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Stoppage} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

type SalesImpactProps = {
  item: Partial<Stoppage>
  update: (delta: Partial<Stoppage>) => void
  costErrorText?: string
  currencyErrorText?: string
  translationPrefix?: string
}

export const SalesImpact: FC<SalesImpactProps> = ({
  item,
  update,
  costErrorText,
  currencyErrorText,
  translationPrefix
}) => {
  const {t} = useTranslation()

  const onToggle = () => {
    if (item.hasSalesImpact) {
      update({hasSalesImpact: false})
    } else {
      update({hasSalesImpact: true})
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box ml={2} mt={1}>
          {t(getTranslationKey('shiftEvent.action.salesImpact', translationPrefix))}
          <Switch
            data-test-id="sales-impact-event-switch"
            color="primary"
            checked={!!item.hasSalesImpact}
            onChange={onToggle}
          />
        </Box>
      </Grid>

      {/*  HCP-42934 uncomment when management decides*/}
      {/*  <Grid item {...REGULAR_SIZE}>*/}
      {/*    <TextField*/}
      {/*      data-test-id="event-form-cost"*/}
      {/*      error={!!costErrorText}*/}
      {/*      fullWidth={true}*/}
      {/*      helperText={costErrorText}*/}
      {/*      label={t('shiftEvent.label.cost')}*/}
      {/*      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>*/}
      {/*        update({cost: Number(event.target.value)})*/}
      {/*      }*/}
      {/*      required*/}
      {/*      value={item.cost}*/}
      {/*      variant="filled"*/}
      {/*      InputProps={INPUT_FORM_FIELD_SX}*/}
      {/*      type="number"*/}
      {/*      inputProps={{*/}
      {/*        onWheel: ({target}) => (target as HTMLInputElement).blur()*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Grid>*/}

      {/*  <Grid item {...REGULAR_SIZE}>*/}
      {/*    <TextField*/}
      {/*      data-test-id="event-form-currency"*/}
      {/*      error={!!currencyErrorText}*/}
      {/*      fullWidth={true}*/}
      {/*      helperText={currencyErrorText}*/}
      {/*      label={t('shiftEvent.label.currency')}*/}
      {/*      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>*/}
      {/*        update({costCurrency: event.target.value})*/}
      {/*      }*/}
      {/*      required*/}
      {/*      value={item.costCurrency}*/}
      {/*      variant="filled"*/}
      {/*      InputProps={INPUT_FORM_FIELD_SX}*/}
      {/*    />*/}
      {/*  </Grid>*/}
    </>
  )
}
