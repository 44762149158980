import {Box} from '@mui/material'
import {MarkdownToJSX} from 'markdown-to-jsx'
import React from 'react'

import {TEXT_SEARCH_HIGHLIGHT_COLOR} from '../consts'

export const getHighlightedTitle = (title: string, highlightText: string, markdown?: boolean) => {
  const regex = new RegExp(`(${highlightText})`, 'i')

  const split = title.split(regex)
  const newString = split.map((subString, index) => {
    if (subString.match(regex)) {
      return markdown ? (
        `<span data-highlighted style="background: ${TEXT_SEARCH_HIGHLIGHT_COLOR}">${subString}</span>`
      ) : (
        <Box
          key={`highlightText-${index}`}
          data-highlighted
          component="span"
          sx={{background: TEXT_SEARCH_HIGHLIGHT_COLOR}}
        >
          {subString}
        </Box>
      )
    }
    return subString
  })
  return markdown ? newString.join('') : newString
}

export const getHighlightedTitleWithLink = (title, highlightText: string, markdown?: boolean) => {
  const regex = new RegExp(
    /(?:^|[ \t])((?:https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(?::\d+)?(?:\/\S*)?)/
  )
  const split = title.split(regex).filter((item) => item)
  const newString = split.map((subStringWithLink: string) => {
    if (!regex.exec(subStringWithLink)) {
      return getHighlightedTitle(subStringWithLink, highlightText, markdown)
    }
    return subStringWithLink
  })
  return markdown ? newString.join(' ') : newString
}

export const generateMarkdownOptions = (highlightText?: string): MarkdownToJSX.Options => ({
  overrides: {
    a: ({children}: {children: string[]}) => {
      const url = children.toString()
      return (
        <>
          <a target="_blank" rel="noopener noreferrer" href={url}>
            {highlightText ? getHighlightedTitle(url, highlightText, false) : children}
          </a>{' '}
        </>
      )
    }
  }
})
