import {AppName} from '../../hproduce'

import {RequestDataResult} from './request-state.types'

export type NotificationsOptions = {
  channels: string[]
  countryCode: string
}

export type NotificationsPayload = {
  channels: string[]
  countryCode: string
  language: string
  offset: number
  limit: number
}

export type Variables = {
  redirectActionUrl?: string
}

export type NotificationResponse = {
  id: string
  channel: string
  createdAt: string
  deliverAt: string
  read: boolean
  title: string
  content: string
  variables: Variables
}

export type Notification = {
  id: string
  channel: string
  plantId: string
  plantName: string
  createdAt: Date
  deliverAt: Date
  read: boolean
  appName: AppName
  markdownTitle: string
  markdownContent: string
  variables: Variables
}

export type GroupedNotifications = Record<string, Notification[]>

export type NotificationsConfig = {
  notificationsRequestData: NotificationsResult
  onNotificationClick: (notification: Notification) => Promise<void>
  newNotificationsDetails: Notification[]
  clearNewNotifications: () => void
  unreadNotificationsCount: number | null
}

export enum TranslationKey {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  NOTIFICATIONS = 'notifications',
  ERROR = 'error',
  EMPTY = 'empty',
  SHOULD_DELETE = 'shouldDelete'
}
export enum TranslationActionKey {
  DELETE = 'delete',
  CONFIRM_DELETE = 'confirmDelete',
  CANCEL = 'cancel'
}

export type Translations = {
  label: Record<TranslationKey, string>
  action: Record<TranslationActionKey, string>
}

export type NotificationsResult = RequestDataResult<Notification[]> & {
  fetchNextPage: () => Promise<void>
  hasNextPage: boolean
  refetch: (omitLoadingState?: boolean, notificationIdToRefresh?: string) => void
}
