import {generatePath} from 'react-router'

import {type AppName} from '../hproduce'
import {appUrl, hproduce, PLANT_CHECKLIST} from '../hproduce/config'

export const getPlantChecklistUrl = ({
  plantId,
  checklistId,
  referer
}: {
  plantId: string
  checklistId: string
  referer: {
    appName: AppName
    pathname: string
    search?: string
  }
}) => {
  const hostUrl = appUrl(hproduce.url)
  const targetPath = generatePath(PLANT_CHECKLIST, {
    plantId,
    checklistId
  })
  const searchParams = new URLSearchParams({
    refererAppName: referer.appName,
    refererPath: referer.pathname + (referer.search ?? '')
  })
  const linkPath = `${hostUrl}${targetPath}?${searchParams.toString()}`

  return linkPath
}
