import {User} from '@hconnect/apiclient'
import {ReactComponent as LogoHProduce} from '@hconnect/uikit/src/common/assets/LogoHproduce_dark_green.svg'
import {LegalDocumentType, TermsAndPrivacyModal} from '@hconnect/uikit/src/lib2'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useLegalAcceptance} from '../../hooks/legal/useLegalAcceptance'
import {
  useLatestDocumentVersion,
  useLegalDocumentAcceptanceRequired
} from '../../hooks/legal/useLegalDocumentsVersions'
import {useApi} from '../../hooks/useApi'
import {getBrowserLanguage} from '../../utils'

import {PrivacyBanner} from './PrivacyBanner'

type Props = {
  user: User
  onAccept?: (refreshedUserDetails) => void
}

export const TermsAndConditions = ({user, onAccept}: Props) => {
  const {i18n} = useTranslation()
  const {publicApi} = useApi()
  const [isAccepted, setIsAccepted] = useState<boolean>(false)

  const country = user?.country?.toLowerCase()

  // TODO: check if privacy policy needs to be enabled in future?
  const isPrivacyPolicyApprovalRequired = useLegalDocumentAcceptanceRequired(
    'dpp',
    country,
    user?.dataPrivacyAcknowledgedVersion
  )
  const isTermsApprovalRequired = useLegalDocumentAcceptanceRequired(
    'tc',
    country,
    user?.termsAcceptedVersion
  )

  const document = isTermsApprovalRequired ? LegalDocumentType.TERMS : LegalDocumentType.NONE

  const latestTcVersionQueryInfo = useLatestDocumentVersion('tc', country?.toLowerCase())
  const latestDppVersionQueryInfo = useLatestDocumentVersion('dpp', country?.toLowerCase())

  const dppVersion = latestDppVersionQueryInfo?.lastAvailableDocumentVersion?.version
  const tcVersion = latestTcVersionQueryInfo?.lastAvailableDocumentVersion?.version
  const preferredLanguage = getBrowserLanguage()

  const userId = user?.user_id || user?.['userId']

  const {accept} = useLegalAcceptance({
    dppVersion,
    tcVersion,
    authorizedApiClient: publicApi,
    username: userId,
    mutationOptions: {
      onSuccess: (data) => {
        if (!data?.['user_id']) return
        onAccept?.(data)
        setIsAccepted(true)
      }
    }
  })

  if (isTermsApprovalRequired && !isAccepted) {
    return (
      <TermsAndPrivacyModal
        showFooter
        preferredLanguage={preferredLanguage}
        documentType={document}
        onAccept={() => accept()}
        logo={LogoHProduce}
        hideAcceptanceTitle={false}
        country={country}
        onLanguageChange={(languageCode: string) => {
          void i18n.changeLanguage(languageCode)
        }}
      />
    )
  }

  if (isPrivacyPolicyApprovalRequired && !isAccepted) {
    return <PrivacyBanner onClose={() => accept()} />
  }

  return null
}
